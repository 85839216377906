"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = exports.CmakConfigImpl = void 0;
var CmakConfigImpl = /** @class */ (function () {
    function CmakConfigImpl(patch) {
        this.signUpAllowed = true;
        this.registrationsAllowed = true;
        this.hideAssignedRoles = true;
        this.emailsAllowed = true;
        if (patch) {
            this.apply(patch);
        }
    }
    CmakConfigImpl.prototype.apply = function (patch) {
        var _this = this;
        Object.entries(patch).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            _this[key] = value;
        });
    };
    return CmakConfigImpl;
}());
exports.CmakConfigImpl = CmakConfigImpl;
exports.config = new CmakConfigImpl();

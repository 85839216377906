var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration"},[(_vm.$route.name === 'Registrace')?_c('div',[_c('v-container',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"8","offset-lg":"2"}},[(!_vm.registrationsAllowed && !_vm.isReadyForPosition(_vm.store.person))?_c('v-alert',{staticClass:"ma-2",attrs:{"border":"left","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" Registrace pro letošní rok byly "),_c('strong',[_vm._v("uzavřeny")]),_vm._v(". Vaše registrace není kompletní a nebude brána v potaz při rozdělování mandátů. ")]):_vm._e(),(!_vm.registrationsAllowed && _vm.isReadyForPosition(_vm.store.person))?_c('v-alert',{staticClass:"ma-2",attrs:{"border":"left","colored-border":"","type":"success","elevation":"2"}},[_vm._v(" Registrace pro letošní rok byly "),_c('strong',[_vm._v("uzavřeny")]),_vm._v(". Vaše registrace je kompletní a bude brána v potaz při rozdělování mandátů. ")]):_vm._e(),_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',[_c('div',{staticClass:"me-2 v-title"},[_vm._v("Základní údaje")]),(
                _vm.store.person.name &&
                _vm.store.person.consent 
              )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.basicsValid),callback:function ($$v) {_vm.basicsValid=$$v},expression:"basicsValid"}},[_c('v-text-field',{attrs:{"label":"Email","required":"","rules":_vm.rules.email},on:{"change":function($event){_vm.basicInfoSaved=false}},model:{value:(_vm.primaryEmail),callback:function ($$v) {_vm.primaryEmail=$$v},expression:"primaryEmail"}}),_c('v-text-field',{attrs:{"label":"Jméno","required":"","rules":_vm.rules.name},on:{"change":function($event){_vm.basicInfoSaved=false}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-btn',{staticClass:"lighten-1",attrs:{"disabled":!(_vm.consent&&_vm.basicsValid),"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.saveBasicInfo()}}},[_vm._v("Uložit"),_c('v-icon',[_vm._v("mdi-create")])],1),(!_vm.consent)?_c('span',{staticClass:"pl-2 caption"},[_vm._v("Nejprve potvrďte souhlas")]):_vm._e(),(!_vm.consent)?_c('div',[_c('v-checkbox',{attrs:{"rules":[
                    function (v) { return !!v ||
                      'Musíte potvrdit souhlas, abyste mohli pokačovat!'; } ],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Souhlasím s "),_c('router-link',{staticClass:"pl-1",attrs:{"to":"/main/terms"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("podmínkami účasti")])],1)]},proxy:true}],null,false,606598363),model:{value:(_vm.consent1),callback:function ($$v) {_vm.consent1=$$v},expression:"consent1"}}),_c('v-checkbox',{attrs:{"rules":[
                    function (v) { return !!v ||
                      'Musíte potvrdit souhlas, abyste mohli pokačovat!'; } ],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Souhlasím se "),_c('router-link',{staticClass:"pl-1",attrs:{"to":"/main/terms"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("zpracováním osobních údajů")])]},proxy:true}],null,false,1605570371),model:{value:(_vm.consent2),callback:function ($$v) {_vm.consent2=$$v},expression:"consent2"}}),_c('v-btn',{attrs:{"elevation":"0","color":"primary","disabled":!(_vm.consent1 && _vm.consent2)},on:{"click":function($event){return _vm.saveConsent()}}},[_vm._v("Přijmout"),_c('v-icon',[_vm._v("mdi-create")])],1)],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',[_c('div',{staticClass:"me-2 v-title"},[_vm._v("Škola")]),(_vm.store.person.school)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.schoolValid),callback:function ($$v) {_vm.schoolValid=$$v},expression:"schoolValid"}},[_c('v-text-field',{attrs:{"label":"Název","required":"","rules":_vm.rules.schoolName},model:{value:(_vm.schoolName),callback:function ($$v) {_vm.schoolName=$$v},expression:"schoolName"}}),_c('address-editor',{attrs:{"address":_vm.schoolAddress},on:{"address-changed":function($event){return _vm.saveAddress($event)},"validity":function($event){_vm.schoolAddressValid = $event}}}),_c('v-btn',{attrs:{"elevation":"0","color":"primary","disabled":!(_vm.consent && _vm.schoolValid && _vm.schoolAddressValid )},on:{"click":function($event){return _vm.saveSchool()}}},[_vm._v("Uložit"),_c('v-icon',[_vm._v("mdi-create")])],1)],1)],1)],1),_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',[_c('div',{staticClass:"me-2 v-title"},[_vm._v("Motivační dopis")]),(
                _vm.store.person.registrationInfo &&
                _vm.store.person.registrationInfo.motivationalLetter
              )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()],1),_c('v-card-text',[_vm._v(" Napište krátký text, ve kterém zdůvodníte, proč bychom měli vybrat právě vás jako účastníka ČMAKu, popřípadě uveďte předchozí zkušenosti. ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"lighten-1",attrs:{"disabled":!_vm.consent,"elevation":"0","to":"/main/registration/motivation-letter","color":"primary"}},[_vm._v(_vm._s(_vm.registrationsAllowed ? "Upravit" : "Náhled")),_c('v-icon',[_vm._v("mdi-create")])],1),(!_vm.consent)?_c('span',{staticClass:"pl-2 caption"},[_vm._v("Nejprve potvrďte souhlas")]):_vm._e()],1)],1),_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',[_c('div',{staticClass:"me-2 v-title"},[_vm._v("Prefererované role")]),(
                _vm.store.person.registrationInfo &&
                _vm.store.person.registrationInfo.primaryRoles &&
                _vm.store.person.registrationInfo.primaryRoles.length == 3
              )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")]):_vm._e()],1),_c('v-card-text',[_vm._v(" Nastavte si preference ohledně vašich rolí v modelu. ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"lighten-1",attrs:{"disabled":!_vm.consent,"elevation":"0","to":"/main/registration/preferences","color":"primary"}},[_vm._v(_vm._s(_vm.registrationsAllowed ? "Upravit" : "Náhled")),_c('v-icon',[_vm._v("mdi-create")])],1),(!_vm.consent)?_c('span',{staticClass:"pl-2 caption"},[_vm._v("Nejprve potvrďte souhlas")]):_vm._e()],1)],1)],1)],1)],1):_vm._e(),_c('router-view',_vm._g({},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
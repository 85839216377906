

























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import LetterEvaluator from "./LetterEvaluator.vue";
import Evaluation from "./Evaluation.vue";
import QuillContent from "./QuillContent.vue";
import { theStore, theCmak, averageEval } from "../cmak-ui";
import { LetterEvaluation, Attendee, Authorization } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import MotivationLetter from "@/views/MotivationLetter.vue";

@Component({ components: { LetterEvaluator, Evaluation } })
export default class RoleAdminDashboard extends Vue {
  @Prop() attendees!:Attendee[]
  
  letterCount: number = 0
  lettersWithEvaluation: number = 0
  evaluatorCount: number = 0
  
  mounted(){
    this.calculateStats(this.attendees)    
  }
  
  @Watch("attendees")
  calculateStats(attendees: Attendee[]) {    
    this.letterCount = attendees.filter(a => a.registrationInfo && a.registrationInfo.motivationalLetter).length
    this.lettersWithEvaluation = attendees.filter(a => a.motivationLetterRatings && a.motivationLetterRatings.filter(le=>le.grammar+le.motivation+le.karasIndex+le.creativity>0).length >= 1).length
    this.evaluatorCount = attendees.filter(a => a.authorizations?.includes(Authorization.EVALUATOR)).length
  }
}













































import Vue from 'vue'
import 'quill'
import Delta from 'quill-delta'
import { Prop, Emit, Component, Watch } from 'vue-property-decorator'

declare var require: any

let Quill: any

if (!Quill) {
  Quill = require('quill')
}

@Component
export default class QuillEditor extends Vue {
  quill: any
  @Prop() content?: Delta = null

  mounted () {
    const toolbarOptions = [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      ['link']
    ]
    const options = {
      modules: {
        toolbar: toolbarOptions
      },
      theme: 'snow'
    }
    this.quill = new Quill('#editor-container', options)
    
    if (this.content) {
      console.log('setting content' + JSON.stringify(this.content))
      this.quill.setContents(this.content)
    }
    this.quill.on('text-change', this.onChange)
  }

  @Watch("content")
  setContent(delta:Delta, oldValue?:Delta){    
    if(delta){
      if(!oldValue){
        console.log('setting initial value ', delta.delta)
        this.quill.setContents(delta.delta, 'api')
      }
    }
  }


  onChange () {
    this.contentsChanged(this.quill.getContents())
  }

  @Emit() contentsChanged (delta: Delta) {

  }
}
















import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import {
  Fee,
  FinanceInfo,
  Person,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";

@Component
export default class FeeList extends Vue {
  @Prop({ required: true }) person!: Person;
  @Prop({ required: true }) fees?: Fee[];
 // @Prop({ required: false }) payment?: boolean;

  total: number | null = null;

  computeTotal(fees:Fee[]){
    return fees?.reduce((total, fee) => (total = total + fee.price), 0)??0
  }
}

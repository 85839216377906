





















































































































































































































































































































































































































































































import {
  Attendee,
  Authorization,
  OrgPosition,
  Position,
  OrganizerInfo,
  PositionFilter,
  ApplicableFilter,
  OrgTeam,
  Services,
  FinanceInfo,
  Sex,
  Fee,
  Person,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import PersonHeader from "../components/PersonHeader.vue";
import AttendeeExport from "../components/AttendeeExport.vue";
// import { Attendee } from "amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { EmittingCmakClient } from "../cmak-client";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  theStore,
  theClient,
  averageEval,
  satisfactionEstimate,
  theCmak,
  distributePositions,
  shortPositionText,
  isReadyForPosition,
  filterToText,
  computeBalance,
} from "../cmak-ui";
import FeeList from "../components/FeeList.vue";

@Component({ components: { PersonHeader, AttendeeExport, FeeList } })
export default class ListOfEveryone extends Vue {
  theClient = new EmittingCmakClient(theClient, this);
  dataLoading: boolean = false;
  people: Attendee[] = [];
  detailDialog: boolean | null = null;
  detailPerson: Attendee | null = null;
  EVALUATOR = Authorization.EVALUATOR;
  ADMIN = Authorization.ADMIN;
  ORGANIZER = Authorization.ORGANIZER;
  ATTENDEE = Authorization.ATTENDEE;
  availableOrgPositions: OrgPosition[] = [];
  detailPosition: OrgPosition | null = null;
  detailAuthorizations: Authorization[] = [];
  detailOrgTeams = [] as OrgTeam[];
  positionFilters: PositionFilter[] = [];
  organizerTeams = theCmak.orgTeams;
  organizerBranches = Array.from(theCmak.orgBranches.entries()).map(
    ([key, value]) => {
      return { key: key, value: value };
    }
  );
  otherFilters = {
    authorizations: [] as Authorization[],
    orgTeams: [] as OrgTeam[],
    debt: null,
    overpay: null,
  };
  filtersExpanded: boolean = false;
  detailFees: Fee[] | null = null;
  detailTeamHead: OrgTeam | null = null;
  detailBranchHead: string | null = null;
  changePaymentSection: boolean | null = null;
  detailBalanceCorrection: number | null = null;
  detailBalanceCorrectionComment: string | null = null;
  nameFilter: string = "";
  rules = {
    paymentChange: [(val) => !isNaN(Number(val)) || "Pouze čísla!"],
  };
  //TODO: pomocna mapa na ukladani authorizations

  created() {
    this.dataLoading = true;
    this.theClient
      .getAllAttendees()
      .then((attendees) => (this.people = attendees))
      .finally(() => (this.dataLoading = false));
    this.availableOrgPositions = theCmak.orgPositions;
  }

  showDetail(person: Attendee) {
    if (!person.authorizations) {
      person.authorizations = [];
    }
    this.detailAuthorizations = person.authorizations;
    this.detailOrgTeams = person.organizer?.orgTeam ?? [];
    this.detailPerson = person;
    this.detailPosition = person.organizer?.orgPosition ?? null;
    this.detailDialog = true;
    this.detailFees = this.detailPerson.financeInfo?.fees ?? null;
    this.detailTeamHead = this.detailPerson.organizer?.teamHead ?? null;
    this.detailBranchHead = this.detailPerson.organizer?.branchHead ?? null;
    this.detailPosition = this.detailPerson.organizer?.orgPosition ?? null;
    this.changePaymentSection = this.detailPerson.financeInfo?.balanceCorrection
      ? true
      : false;
    this.detailBalanceCorrection =
      this.detailPerson.financeInfo?.balanceCorrection ?? null;
    this.detailBalanceCorrectionComment =
      this.detailPerson.financeInfo?.balanceCorrectionComment ?? null;
  }

  isAdmin(attendee: Attendee): boolean {
    return attendee.authorizations?.includes(this.ADMIN) ?? false;
  }
  isEvaluator(attendee: Attendee): boolean {
    return attendee.authorizations?.includes(this.EVALUATOR) ?? false;
  }
  isOrg(attendee: Attendee): boolean {
    return attendee.authorizations?.includes(this.ORGANIZER) ?? false;
  }
  isAttendee(attendee: Attendee): boolean {
    return attendee.authorizations?.includes(Authorization.ATTENDEE) ?? false;
  }
  orgPositionName(attendee: Attendee): string {
    return attendee.organizer?.orgPosition?.name ?? "Žádná";
  }
  saveOrgPosition() {
    const orgInfo = this.detailPerson!.organizer ?? ({} as OrganizerInfo);
    orgInfo.orgPosition = this.detailPosition ?? undefined;
    this.detailPerson!.organizer = orgInfo;
  }

  computeBalance(financeInfo?: FinanceInfo): number {
    return computeBalance(financeInfo);
  }

  savePerson() {
    let newOrganizer: OrganizerInfo | undefined;
    if (this.detailAuthorizations.includes(Authorization.ORGANIZER)) {
      newOrganizer = this.detailPerson?.organizer ?? {
        orgTeam: this.detailOrgTeams ?? ([] as OrgTeam[]),
      };
      newOrganizer.orgTeam = this.detailOrgTeams;
      newOrganizer.orgPosition = this.detailPosition ?? undefined;
      newOrganizer.teamHead = this.detailTeamHead ?? undefined;
      newOrganizer.branchHead = this.detailBranchHead ?? undefined;
      console.log(newOrganizer.orgPosition);
    } else {
      newOrganizer = undefined;
    }
    let newFinanceInfo: FinanceInfo | undefined;
    if (this.detailBalanceCorrection && this.detailPerson?.financeInfo) {
      newFinanceInfo = {
        paid: this.detailPerson?.financeInfo?.paid,
        fees: this.detailPerson?.financeInfo?.fees,
        varSymbol: this.detailPerson?.financeInfo?.varSymbol,
        balanceCorrection: this.detailBalanceCorrection,
        balanceCorrectionComment: this.detailBalanceCorrectionComment ?? "",
      };
    }

    let attendeePatch: Partial<Attendee> = {
      authorizations: this.detailAuthorizations,
      organizer: newOrganizer,
      financeInfo: newFinanceInfo,
    };
    this.theClient.updateAttendee(attendeePatch, this.detailPerson!.id);
    Vue.set(this.detailPerson!, "authorizations", this.detailAuthorizations);
    Vue.set(this.detailPerson!, "organizer", newOrganizer);
    Vue.set(this.detailPerson!, "financeInfo", newFinanceInfo);
  }
  shortPositionText(position: Position) {
    return shortPositionText(position);
  }

  filtered(people: Attendee[]): Attendee[] {
    const applicableFilters: ApplicableFilter[] =
      ApplicableFilter.makeApplicable(this.positionFilters);
    return people.filter((p) => {
      if (
        !this.otherFilters.authorizations.every((a) =>
          p.authorizations?.includes(a)
        )
      ) {
        return false;
      }
      if (
        !this.otherFilters.orgTeams.every((t) =>
          p.organizer?.orgTeam.includes(t)
        )
      ) {
        return false;
      }
      if (this.nameFilter?.length > 0) {
        if (!(p.name == this.nameFilter)) {
          return false;
        }
      }
      if (this.otherFilters.debt) {
        if (this.computeBalance(p.financeInfo) >= 0) {
          return false;
        }
      }
      if (this.otherFilters.overpay) {
        if (this.computeBalance(p.financeInfo) <= 0) {
          return false;
        }
      }
      return true;
    });
  }

  removeFilter(index: number) {
    this.detailOrgTeams.splice(index, 1);
  }
}

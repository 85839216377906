"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateFromSimpleDate = exports.getSimpleDateFromDate = exports.getRatingType = exports.getAsPerson = exports.calculateSingleRatingOverall = exports.getRatingCategories = exports.isWithinFilters = exports.validatePositionDistribution = exports.grantAttendees = exports.generateVarSymbol = exports.demandEstimate = exports.feeGenerator = exports.positionToText = exports.LobbyTypeFilter = exports.StateFilter = exports.FractionFilter = exports.PartyFilter = exports.CommitteeFilter = exports.BodyFilter = exports.ApplicableFilter = exports.LobbyType = exports.State = exports.CongressCommitteeId = exports.SenateCommitteeId = exports.BodyType = exports.Party = exports.Dorm = exports.Sex = exports.Authorization = void 0;
var Authorization;
(function (Authorization) {
    Authorization["ADMIN"] = "Admin";
    Authorization["ORGANIZER"] = "Organiz\u00E1tor";
    Authorization["EVALUATOR"] = "Hodnotitel";
    Authorization["ATTENDEE"] = "\u00DA\u010Dastn\u00EDk";
})(Authorization = exports.Authorization || (exports.Authorization = {}));
var Sex;
(function (Sex) {
    Sex["FEMALE"] = "\u017Dena";
    Sex["MALE"] = "Mu\u017E";
})(Sex = exports.Sex || (exports.Sex = {}));
var Dorm;
(function (Dorm) {
    Dorm["Bolevecka"] = "Boleveck\u00E1";
    Dorm["Heyrovskeho"] = "Heyrovsk\u00E9ho";
})(Dorm = exports.Dorm || (exports.Dorm = {}));
// example
// [
//     [{type:"party",value:"Democrat"},{type:"committee",value:"VDR"}]
//     [{type:"party",value:"Republican"}
// ]
var Party;
(function (Party) {
    Party["Republican"] = "Republican";
    Party["Democrat"] = "Democrat";
})(Party = exports.Party || (exports.Party = {}));
var BodyType;
(function (BodyType) {
    BodyType["Senate"] = "Senate";
    BodyType["Congress"] = "Congress";
    BodyType["Government"] = "Government";
    BodyType["Lobby"] = "Lobby";
    BodyType["TCK"] = "TCK";
    BodyType["Other"] = "Other";
})(BodyType = exports.BodyType || (exports.BodyType = {}));
var SenateCommitteeId;
(function (SenateCommitteeId) {
    SenateCommitteeId["VOS"] = "VOS";
    SenateCommitteeId["VPZ"] = "VPZ";
    SenateCommitteeId["VJ"] = "VJ";
})(SenateCommitteeId = exports.SenateCommitteeId || (exports.SenateCommitteeId = {}));
var CongressCommitteeId;
(function (CongressCommitteeId) {
    CongressCommitteeId["VDR"] = "VDR";
    CongressCommitteeId["VEH"] = "VEH";
    CongressCommitteeId["VNB"] = "VNB";
    CongressCommitteeId["VSP"] = "VSP";
    CongressCommitteeId["VVVT"] = "VVVT";
    CongressCommitteeId["VZV"] = "VZV";
})(CongressCommitteeId = exports.CongressCommitteeId || (exports.CongressCommitteeId = {}));
var State;
(function (State) {
    State["Alabama"] = "Alabama";
    State["Alaska"] = "Alja\u0161ka";
    State["Arizona"] = "Arizona";
    State["Arkansas"] = "Arkansas";
    State["Colorado"] = "Colorado";
    State["Connecticut"] = "Connecticut";
    State["Delaware"] = "Delaware";
    State["DistrictOfColumbia"] = "District Of Columbia";
    State["Florida"] = "Florida";
    State["Georgia"] = "Georgia";
    State["Hawaii"] = "Hawaj";
    State["Idaho"] = "Idaho";
    State["Illinois"] = "Illinois";
    State["Indiana"] = "Indiana";
    State["Iowa"] = "Iowa";
    State["California"] = "Kalifornie";
    State["Kansas"] = "Kansas";
    State["Kentucky"] = "Kentucky";
    State["Lousiana"] = "Lousiana";
    State["Maine"] = "Maine";
    State["Maryland"] = "Maryland";
    State["Massachusetts"] = "Massachusetts";
    State["Michigan"] = "Michigan";
    State["Minnesota"] = "Minnesota";
    State["Mississippi"] = "Mississippi";
    State["Missouri"] = "Missouri";
    State["Montana"] = "Montana";
    State["Nebraska"] = "Nebraska";
    State["Nevada"] = "Nevada";
    State["NewHampshire"] = "New Hampshire";
    State["NewJersey"] = "New Jersey";
    State["NewMexico"] = "Nov\u00E9 Mexico";
    State["NewYork"] = "New York";
    State["NorthernDakota"] = "Severn\u00ED Dakota";
    State["NorthernCarolina"] = "Severn\u00ED Carolina";
    State["Ohio"] = "Ohio";
    State["Oklahoma"] = "Oklahoma";
    State["Oregon"] = "Oregon";
    State["Pennsylvania"] = "Pennsylv\u00E1nie";
    State["RhodeIsland"] = "Rhode Island";
    State["SouthernCarolina"] = "Ji\u017En\u00ED Karol\u00EDna";
    State["SouthernDakota"] = "Ji\u017En\u00ED Dakota";
    State["Tennessee"] = "Tennessee";
    State["Texas"] = "Texas";
    State["Utah"] = "Utah";
    State["Vermont"] = "Vermont";
    State["Virginia"] = "Virginie";
    State["Washington"] = "Washington";
    State["Wisconsin"] = "Wisconsin";
    State["Wyoming"] = "Wyoming";
    State["WesternVirginia"] = "Z\u00E1padn\u00ED Virginie";
})(State = exports.State || (exports.State = {}));
var LobbyType;
(function (LobbyType) {
    LobbyType["Np"] = "Non-profit";
    LobbyType["Corp"] = "Corporation";
    LobbyType["Un"] = "Union";
})(LobbyType = exports.LobbyType || (exports.LobbyType = {}));
/*

body : lobby
committee:x
state Cali
state NY
party
org:  media/lobby

*/
var ApplicableFilter = /** @class */ (function () {
    function ApplicableFilter(value) {
        this.value = value;
    }
    ;
    ApplicableFilter.makeApplicable = function (filters) {
        return filters.map(function (f) {
            switch (f.type) {
                case 'committee':
                    return new CommitteeFilter(f);
                case 'party':
                    return new PartyFilter(f);
                case 'fraction':
                    return new FractionFilter(f);
                case 'body':
                    return new BodyFilter(f);
                case 'state':
                    return new StateFilter(f);
                case 'lobbyType':
                    return new LobbyTypeFilter(f);
            }
        });
    };
    ApplicableFilter.extractMatchingFilters = function (position) {
        return __spreadArrays(BodyFilter.extract(position), CommitteeFilter.extract(position), PartyFilter.extract(position), FractionFilter.extract(position), LobbyTypeFilter.extract(position));
    };
    return ApplicableFilter;
}());
exports.ApplicableFilter = ApplicableFilter;
var BodyFilter = /** @class */ (function (_super) {
    __extends(BodyFilter, _super);
    function BodyFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'body';
        if ('type' in filter && filter.type != _this.type) {
            throw new Error('incorrect filter type (' + filter.type + ')');
        }
        return _this;
    }
    BodyFilter.prototype.matches = function (position) {
        var bodyType = this.value;
        return bodyType === position.body;
    };
    BodyFilter.extract = function (position) {
        return [{ type: 'body', value: position.body }];
    };
    return BodyFilter;
}(ApplicableFilter));
exports.BodyFilter = BodyFilter;
var CommitteeFilter = /** @class */ (function (_super) {
    __extends(CommitteeFilter, _super);
    function CommitteeFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'committee';
        if ('type' in filter && filter.type != _this.type) {
            throw new Error('incorrect filter type');
        }
        return _this;
    }
    CommitteeFilter.prototype.matches = function (position) {
        var committeeId = this.value;
        switch (position.body) {
            case BodyType.Senate:
            case BodyType.Congress: {
                var committeeIdToMatch = position.committee;
                return committeeId === committeeIdToMatch;
            }
            case BodyType.Government: {
                var govPosition = position;
                return govPosition.availableCommittees.includes(committeeId);
            }
            default:
                return false;
        }
    };
    CommitteeFilter.extract = function (position) {
        switch (position.body) {
            case BodyType.Senate:
            case BodyType.Congress:
                return [{
                        type: 'committee',
                        value: position.committee
                    }];
            case BodyType.Government: {
                var govPosition = position;
                return govPosition.availableCommittees.map(function (c) { return { type: 'committee', value: c }; });
            }
            default:
                return [];
        }
    };
    return CommitteeFilter;
}(ApplicableFilter));
exports.CommitteeFilter = CommitteeFilter;
var PartyFilter = /** @class */ (function (_super) {
    __extends(PartyFilter, _super);
    function PartyFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'party';
        if ('type' in filter && filter.type != _this.type) {
            throw new Error('incorrect filter type');
        }
        return _this;
    }
    PartyFilter.prototype.matches = function (position) {
        var party = position.party;
        return party === this.value;
    };
    PartyFilter.extract = function (position) {
        switch (position.body) {
            case BodyType.Senate:
            case BodyType.Congress: {
                return [{ type: 'party', value: position.party }];
            }
            default:
                return [];
        }
    };
    return PartyFilter;
}(ApplicableFilter));
exports.PartyFilter = PartyFilter;
var FractionFilter = /** @class */ (function (_super) {
    __extends(FractionFilter, _super);
    function FractionFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'fraction';
        if (filter.type != _this.type) {
            throw new Error('incorrect filter type');
        }
        _this.value = filter.value;
        return _this;
    }
    FractionFilter.prototype.matches = function (position) {
        var _a;
        var fractionId = (_a = position.fraction) === null || _a === void 0 ? void 0 : _a.id;
        return fractionId === this.value;
    };
    FractionFilter.extract = function (position) {
        switch (position.body) {
            case BodyType.Congress: {
                return [{ type: 'fraction', value: position.fraction.id }];
            }
            case BodyType.Senate: {
                return [{ type: 'fraction', value: position.fraction.id }];
            }
            default:
                return [];
        }
    };
    return FractionFilter;
}(ApplicableFilter));
exports.FractionFilter = FractionFilter;
var StateFilter = /** @class */ (function (_super) {
    __extends(StateFilter, _super);
    function StateFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'state';
        if ('type' in filter && filter.type != _this.type) {
            throw new Error('incorrect filter type');
        }
        return _this;
    }
    StateFilter.prototype.matches = function (position) {
        var state = position.state;
        return state === this.value;
    };
    return StateFilter;
}(ApplicableFilter));
exports.StateFilter = StateFilter;
var LobbyTypeFilter = /** @class */ (function (_super) {
    __extends(LobbyTypeFilter, _super);
    function LobbyTypeFilter(filter) {
        var _this = _super.call(this, filter.value) || this;
        _this.type = 'lobbyType';
        if ('type' in filter && filter.type != _this.type) {
            throw new Error('incorrect filter type');
        }
        return _this;
    }
    LobbyTypeFilter.extract = function (position) {
        switch (position.body) {
            case BodyType.Lobby: {
                return [{ type: 'lobbyType', value: position.organization.type }];
            }
            default:
                return [];
        }
    };
    LobbyTypeFilter.prototype.matches = function (position) {
        var _a;
        var lobbyType = (_a = position.organization) === null || _a === void 0 ? void 0 : _a.type;
        return lobbyType === this.value;
    };
    return LobbyTypeFilter;
}(ApplicableFilter));
exports.LobbyTypeFilter = LobbyTypeFilter;
function positionToText(positionToConvert, committeesById) {
    var _a, _b, _c, _d;
    switch (positionToConvert.body) {
        case BodyType.Senate: {
            var party = void 0;
            var position = positionToConvert;
            if (position.party === "Republican") {
                party = "Republikány";
            }
            else {
                party = "Demokraty";
            }
            return "Sen\u00E1tor za " + party + "/" + ((_a = position.fraction) === null || _a === void 0 ? void 0 : _a.name) + ", " + position.state + " ve " + ((_b = committeesById.get(position.committee)) === null || _b === void 0 ? void 0 : _b.name.replace("Výbor", "Výboru"));
        }
        case BodyType.Congress: {
            var position = positionToConvert;
            var party = void 0;
            if (position.party === "Republican") {
                party = "Republikány";
            }
            else {
                party = "Demokraty";
            }
            return "Poslanec za " + party + "/" + ((_c = position.fraction) === null || _c === void 0 ? void 0 : _c.name) + ", " + position.state + " ve " + ((_d = committeesById.get(position.committee)) === null || _d === void 0 ? void 0 : _d.name.replace("Výbor", "Výboru")) + " ";
        }
        case BodyType.Government: {
            var position = positionToConvert;
            return "Ministr: " + position.department.name;
        }
        case BodyType.Lobby: {
            var position = positionToConvert;
            return "Lobista za " + position.organization.name;
        }
        case BodyType.TCK: {
            return "Novinář ";
        }
        default:
            return "Neznámá pozice";
    }
}
exports.positionToText = positionToText;
function feeGenerator(services, prices) {
    var _a;
    var fees = [];
    if (services === null || services === void 0 ? void 0 : services.lunch) {
        fees.push({ name: "Obědy", price: prices.lunch });
    }
    if (services === null || services === void 0 ? void 0 : services.transport) {
        fees.push({ name: "Celotýdenní jízdenka na MHD v Plzni", price: prices.transport });
    }
    if ((_a = services === null || services === void 0 ? void 0 : services.accommodationInfo) === null || _a === void 0 ? void 0 : _a.nights) {
        fees.push({ name: services.accommodationInfo.nights + " nocí na kolejích UK", price: (prices.night) * services.accommodationInfo.nights });
    }
    fees.push({ name: "Účastnický poplatek", price: prices.participation });
    return fees;
}
exports.feeGenerator = feeGenerator;
function demandEstimate(attendees, roles) {
    var _a;
    console.log("calculating demand of " + attendees.length + " attendees");
    var roleAvailability = (_a = roles === null || roles === void 0 ? void 0 : roles.reduce(function (map, p) { var _a; map.set(p.identifier, ((_a = map.get(p.identifier)) !== null && _a !== void 0 ? _a : 0) + 1); return map; }, new Map())) !== null && _a !== void 0 ? _a : new Map();
    var result = new Map();
    attendees.forEach(function (a) {
        var _a, _b;
        ((_b = (_a = a.registrationInfo) === null || _a === void 0 ? void 0 : _a.primaryRoles) !== null && _b !== void 0 ? _b : []).forEach(function (p) {
            var _a, _b;
            if (p) {
                result.set(p.identifier, ((_a = result.get(p.identifier)) !== null && _a !== void 0 ? _a : 0) + 1 / ((_b = roleAvailability.get(p.identifier)) !== null && _b !== void 0 ? _b : 1));
            }
        });
    });
    return Array.from(result.entries());
}
exports.demandEstimate = demandEstimate;
function generateVarSymbol(id, existingVarSymbols) {
    var result = 0;
    for (var i = 0; i < id.length; i++) {
        result = (result * 13 + id.charCodeAt(i)) % 1000000;
    }
    var prefix = new Date().getFullYear() * 1000000;
    while (existingVarSymbols.includes(prefix + result)) {
        result = (result + 1) % 1000000;
    }
    return prefix + result;
}
exports.generateVarSymbol = generateVarSymbol;
/**
 * grants attendee authorization and initializes finance info for those that have position assigned and do not have any authorization yet
 * @param attendees
 * @param pricelist
 * @returns
 */
function grantAttendees(attendees, pricelist) {
    var result = {
        accepted: [],
        rejected: []
    };
    var symbols = attendees.map(function (a) { var _a, _b; return (_b = (_a = a.financeInfo) === null || _a === void 0 ? void 0 : _a.varSymbol) !== null && _b !== void 0 ? _b : 0; }).filter(function (x) { return x > 0; });
    attendees.forEach(function (attendee) {
        var _a;
        if ((_a = attendee.authorizations) === null || _a === void 0 ? void 0 : _a.length) {
            return; //any authorization means we do not want to continue processing
        }
        if (attendee.position) {
            //newly accepted
            if (!attendee.financeInfo) {
                var varSymbol = generateVarSymbol(attendee.id, symbols);
                symbols.push(varSymbol);
                attendee.financeInfo = {
                    fees: feeGenerator(undefined, pricelist),
                    paid: 0,
                    varSymbol: varSymbol
                };
            }
            attendee.rejected = false;
            attendee.authorizations = [Authorization.ATTENDEE];
            result.accepted.push(attendee);
        }
        else {
            if (!attendee.rejected) {
                //newly rejected
                attendee.rejected = true;
                result.rejected.push(attendee);
            }
        }
    });
    return result;
}
exports.grantAttendees = grantAttendees;
function validatePositionDistribution(map, positions) {
    var remainingPositions = __spreadArrays(positions);
    try {
        Object.entries(map).forEach(function (_a) {
            var userId = _a[0], position = _a[1];
            var index = remainingPositions.findIndex(function (p) { return p.identifier == position.identifier; });
            if (index < 0) {
                throw "failed";
            }
            else {
                remainingPositions.splice(index, 1);
            }
        });
    }
    catch (e) {
        return false;
    }
    return true;
}
exports.validatePositionDistribution = validatePositionDistribution;
function isWithinFilters(attendee, positionFilters) {
    if (positionFilters.find(function (singleFilter) {
        return attendee.position ? singleFilter.matches(attendee.position) : false;
    })) {
        return true;
    }
    else {
        return false;
    }
}
exports.isWithinFilters = isWithinFilters;
function getRatingCategories(position) {
    var type = getRatingType(position);
    switch (type) {
        case "Lobby": {
            return [
                { key: "lobbing", label: "Lobbing" },
                { key: "readiness", label: "Připravenost" },
                { key: "mediaImage", label: "Mediální obraz" }
            ];
        }
        case "Congress": {
            return [
                { key: "activity", label: "Aktivita" },
                { key: "readiness", label: "Připravenost" },
                { key: "authenticity", label: "Autentičnost" },
                { key: "teamwork", label: "Týmová práce" }
            ];
        }
        case "TCK": {
            return [
                { key: "creativity", label: "Kreativita" },
                { key: "initiative", label: "Iniciativa" },
                { key: "integrity", label: "Integrita" },
            ];
        }
        case "Government": {
            return [
                { key: "activity", label: "Aktivita" },
                { key: "readiness", label: "Připravenost" },
                { key: "teamwork", label: "Týmová práce" }
            ];
        }
        default:
            return [];
    }
}
exports.getRatingCategories = getRatingCategories;
function calculateSingleRatingOverall(person, rating) {
    return getRatingCategories(person.position).reduce(function (avg, curr, index, categories) {
        return avg + (rating[curr.key]) / categories.length;
    }, 0);
}
exports.calculateSingleRatingOverall = calculateSingleRatingOverall;
function getAsPerson(attendee) {
    var person = {
        name: attendee.name,
        pic: attendee.pic,
        email: attendee.email,
        id: attendee.id,
        uuid: attendee.uuid
    };
    return person;
}
exports.getAsPerson = getAsPerson;
function getRatingType(position) {
    switch (position.body) {
        case BodyType.Congress:
        case BodyType.Senate:
            return "Congress";
        case BodyType.TCK:
            return "TCK";
        case BodyType.Government:
            return "Government";
        case BodyType.Lobby:
            return "Lobby";
        case BodyType.Other:
            return null;
    }
}
exports.getRatingType = getRatingType;
function getSimpleDateFromDate(date) {
    var returnDate = date ? {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    } : null;
    return returnDate;
}
exports.getSimpleDateFromDate = getSimpleDateFromDate;
function getDateFromSimpleDate(date) {
    return date ? new Date(date.year, date.month - 1, date.day - 1) : null;
}
exports.getDateFromSimpleDate = getDateFromSimpleDate;

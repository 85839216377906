






















import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import RoleSelection from "./RoleSelection.vue";
import { Attendee, Position, Person } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { shortPositionText, theConfig } from "../cmak-ui";
@Component({ components: { RoleSelection } })
export default class PersonHeader extends Vue {
  @Prop() person?: Person | Attendee;
  hideAssignedRoles: boolean|null = null

  created() {
    theConfig.get().then(c => this.hideAssignedRoles = c.hideAssignedRoles)
  }

  shortInfo(): string {
    if (this.person && "position" in this.person && !this.hideAssignedRoles) {
      return shortPositionText(this.person.position as Position);
    }else if (this.person && this.person.organizer?.orgPosition) {
       return this.person.organizer?.orgPosition.name
    } else if (this.person && "position" in this.person === false) {
      return this.person.email;
    } else {
      return "";
    }
  }
}























































































import { Vue, Component, Watch } from "vue-property-decorator";
import LetterEvaluator from "./LetterEvaluator.vue";
import Evaluation from "./Evaluation.vue";
import QuillContent from "./QuillContent.vue";
import { theStore, theClient, averageEval } from "../cmak-ui";
import {EmittingCmakClient} from "../cmak-client";

import { LetterEvaluation } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import MotivationLetter from "@/views/MotivationLetter.vue";

@Component({ components: { LetterEvaluator, Evaluation } })
export default class EvaluatorDashboard extends Vue {
  //po evaluation period napise - it's over
  //pokud se prihlasi evaluator - prehled dopisu, statistika
  theClient=new EmittingCmakClient(theClient,this)
  lettersEvals: {
    id: string;
    eval: LetterEvaluation;
  }[] = [];

  lettersEvaluated: string[] = [];

  listView: boolean = true;
  selected: string = "";
  selectedEvaluation?: LetterEvaluation | null = null;
  averageEvaluation?: LetterEvaluation | null = null;
  evaluatedCount?: number = 0;

  created() {
    this.theClient
      .getEvaluations(theStore.person!.email)
      .then(this.evaluationsLoaded);
  }

  @Watch("lettersEvals")
  evalsUpdated(
    newEvals: {
      id: string;
      eval: LetterEvaluation;
    }[]
  ) {
    const evaluated = this.lettersEvals
      .map((e) => e.eval)
      .filter(this.isEvaled);
    this.averageEvaluation = averageEval(evaluated);
    this.evaluatedCount = evaluated.length;
  }

  // evalsCount(evaluations: { id: string; eval: LetterEvaluation }[]): number {
  //   const x = evaluations.filter((e) => this.isEvaled(e.eval)).length;

  //   return x;
  // }

  isEvaled(evaluation: LetterEvaluation): boolean {
    return (
      evaluation.grammar != 0 &&
      evaluation.creativity != 0 &&
      evaluation.motivation != 0 &&
      evaluation.karasIndex != 0
    );
  }

  evaluationsLoaded(newEvaluations: Map<string, LetterEvaluation>) {
    this.lettersEvals = newEvaluations instanceof Map?
    Array.from(newEvaluations.entries()).map((entry) => {
      return { id: entry[0], eval: entry[1] }}):
    Array.from(Object.keys(newEvaluations)).map((key) => {
      return { id: key, eval: newEvaluations[key] }});    
  }

  toLetter(id: string) {
    this.selected = id;
    this.selectedEvaluation = this.lettersEvals?.filter(
      (i) => i.id == id
    )[0].eval;
  }

  reset() {
    this.selected = "";
  }
  addLetter() {
    this.theClient
      .getNewEvaluation(theStore.person!.email)
      .then(([uuid, emptyEval]) => {
        if (uuid) {
          this.lettersEvals?.push({ id: uuid, eval: emptyEval });
        }
      })
  }
  addEvaluated(id: string) {
    this.lettersEvaluated?.push(id);
  }
}




















































































































import {
  Letter,
  LetterEvaluation,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import QuillContent from "./QuillContent.vue";
import Vue from "vue";
import { theClient } from "../cmak-ui";
import { EmittingCmakClient } from "../cmak-client";
import { component } from "node_modules/vue/types/umd";

@Component({ components: { QuillContent } })
export default class LetterEvaluator extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop() evaluation!: LetterEvaluation;

  loading = false;

  motivationalLetter?: Letter | null = null;
  theClient = new EmittingCmakClient(theClient, this);

  mounted() {
    this.loadLetter(this.id);
  }
  @Watch("id")
  loadLetter(id: string) {
    this.loading = true;
    this.theClient
      .getMotivationLetterByUuid(id)
      .then((letter) => {
        this.motivationalLetter = letter;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Emit()
  async submitEvaluation() {
    await this.theClient.submitEvaluations(
      new Map([[this.id, this.evaluation]])
    );
    this.theClient.close();
  }
  @Emit()
  close() {
    this.theClient.close();
  }
}

import Vue from "vue";
// import Vuetify from 'vuetify/lib/framework';
import Vuetify, {
  VFlex,
  VLayout,
  VContainer,
  VImg,
  VApp,
  VBtn,
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VCardText,
  VCardSubtitle,
  VCardActions,
  VForm,
  VTextField,
  VTextarea,
  VSelect,
  VSpacer,
  VIcon,
  VTimeline,
  VTimelineItem,
  VList,
  VListGroup,
  VListItem,
  VListItemGroup,
  VListItemTitle,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemAction,
  VListItemActionText,
  VListItemSubtitle,
  VMain,
  VAvatar,
  VAppBar,
  VAppBarNavIcon,
  VNavigationDrawer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VMenu,
  VSubheader,
  VToolbarTitle,
  VCheckbox,
  VChip,
  VProgressCircular,
  VDialog,
  VSlider,
  VVirtualScroll,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VBadge,
  VToolbar,
  VToolbarItems,
  VSwitch,
  VAutocomplete,
  VFooter,
  VDivider,
  VSnackbar,
  VProgressLinear,
  VAlert,
  VRadio,
  VRadioGroup,
  VDatePicker,
  VFileInput,
  VTab,
  VTabs,
  VTabItem,
  VTabsItems,
  VTabsSlider,

} from "vuetify/lib";
import cs from "vuetify/src/locale/cs";

Vue.use(Vuetify, {
  components: {
    VFlex,
  VLayout,
  VContainer,
  VImg,
  VApp,
  VBtn,
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VCardText,
  VCardSubtitle,
  VCardActions,
  VForm,
  VTextField,
  VTextarea,
  VSelect,
  VSpacer,
  VIcon,
  VTimeline,
  VTimelineItem,
  VList,
  VListGroup,
  VListItem,
  VListItemGroup,
  VListItemTitle,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemAction,
  VListItemActionText,
  VListItemSubtitle,
  VMain,
  VAvatar,
  VAppBar,
  VAppBarNavIcon,
  VNavigationDrawer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VMenu,
  VSubheader,
  VToolbarTitle,
  VCheckbox,
  VChip,
  VProgressCircular,
  VDialog,
  VSlider,
  VVirtualScroll,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VBadge,
  VToolbar,
  VToolbarItems,
  VSwitch,
  VAutocomplete,
  VDivider,
  VFooter,
  VSnackbar,
  VProgressLinear,
  VAlert,
  VRadio,
  VRadioGroup,
  VDatePicker,
  VFileInput,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTabsSlider
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1e88e5", //{base:"#1e88e5",lighten1:"#6ab7ff",darken1:"#005cb2"},
        secondary: "#a0a0a0",
        accent: "#ef5350",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { cs },
    current: "cs",
  },
});

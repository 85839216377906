































































































































































import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import RoleSelection from "../components/RoleSelection.vue";
import FilterSelector from "../components/FilterSelector.vue";
import {
  Position,
  PartisanPosition,
  PositionFilter,
  BodyType,
  SenatePosition,
  CongressPosition,
  Party,
  State,
  PartyFilter,
  Committee,
  Fraction,
  ApplicableFilter,
  RegistrationInfo,
  Person,
  Attendee,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import {
  shortPositionText,
  filterToText,
  theCmak,
  theClient,
  theStore,
  theConfig  
} from "../cmak-ui";
import {EmittingCmakClient} from "../cmak-client";
@Component({ components: { RoleSelection, FilterSelector } })
export default class Preferences extends Vue {
  theClient=new EmittingCmakClient(theClient,this)
  stepsComplete: number = 1;  
  cmakDefinition = theCmak;
  demand: Map<string, number> = new Map();
  positions = new Array<Position>(3);

  filters: Array<PositionFilter> = [];
  filterGenerators: { label: string; generator: () => PositionFilter[] }[] = [];
  registrationsAllowed = true

  created() {
    this.filters =
      theStore.person!.registrationInfo?.secondaryRoleFilters ?? [];
    this.positions = theStore.person!.registrationInfo?.primaryRoles ?? [];
    this.theClient.getPositionDemand().then((demand) => (this.demand = demand));
    theConfig.get().then(c => this.registrationsAllowed = c.registrationsAllowed)
  }

  @Watch("positions")
  onPositionsChange() {
    console.log("onPositionsChange", this);
    if (this.positions && this.positions.length > 0 && this.positions[0]) {
      console.log("onPositions Changed");
      this.filterGenerators = [
        { label: "Podle 1. preference", generator: this.generateFilters },
      ];
    }
  }

  toText(position: Position) {
    return position ? shortPositionText(position) : "";
  }

  isFilterSelected(value: string): boolean {
    return this.filters.filter((f) => f.value === value).length > 0;
  }

  committees(): Committee[] {
    return [
      ...this.cmakDefinition.congressCommittees,
      ...this.cmakDefinition.senateCommittees,
    ];
  }

  states(): string[] {
    return Object.values(State);
  }

  fractions(): Fraction[] {
    return this.cmakDefinition.fractions;
  }

  addFilter(filter: PositionFilter) {
    console.log("new filter", filter.type, filter.value);
    if (this.isNewFilter(filter.type, filter.value)) {
      this.filters.push({ type: filter.type, value: filter.value });
    }
  }

  private isNewFilter(filterType: string, value: string): boolean {
    return (
      this.filters.filter((f) => f.type === filterType && f.value === value)
        .length === 0
    );
  }

  savePreferences() {
    //get Person -> save info to his registrationInfo
    let registrationInfo: RegistrationInfo =
      theStore.person?.registrationInfo ?? {};
    registrationInfo.primaryRoles = this.positions;
    registrationInfo.secondaryRoleFilters = this.filters;
    this.theClient
      .updateRegistration(registrationInfo)
      .then((a) => {
        theStore.person = a;
      })
      .then(() => {
        this.$router.push({ path: "/main/registration" });
      });
  }

  filterToText(filter: PositionFilter) {
    return filterToText(filter);
  }

  moveUp(index: number) {
    [this.filters[index], this.filters[index - 1]] = [
      this.filters[index - 1],
      this.filters[index],
    ];
    this.filters = [...this.filters];
  }

  moveDown(index: number) {
    [this.filters[index], this.filters[index + 1]] = [
      this.filters[index + 1],
      this.filters[index],
    ];
    this.filters = [...this.filters];
  }

  deleteFilter(value: string) {
    this.filters = this.filters.filter((f) => f.value !== value);
  }

  onPosition(position: Position, index: number) {
    this.positions[index] = position;
    this.positions = [...this.positions];
    this.onPositionsChange();
  }

  generateFilters(): PositionFilter[] {
    return ApplicableFilter.extractMatchingFilters(this.positions[0]);
  }
}









































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import LetterEvaluator from "./LetterEvaluator.vue";
import Evaluation from "./Evaluation.vue";
import QuillContent from "./QuillContent.vue";
import {
  theStore,
  theCmak,
  averageEval,
  theClient,
  computeBalance,
} from "../cmak-ui";
import { EmittingCmakClient } from "../cmak-client";
import {
  LetterEvaluation,
  Attendee,
  Authorization,
  feeGenerator,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import MotivationLetter from "@/views/MotivationLetter.vue";

interface Payment {
  amount?: number;
  vs?: number;
  comment?: string;
  attendee?: Attendee;
}

@Component({ components: { LetterEvaluator, Evaluation } })
export default class PaymentsDashboard extends Vue {
  theClient = new EmittingCmakClient(theClient, this);
  @Prop() attendees!: Attendee[];

  paymentsDialog: boolean = false;

  totalOrdered(attendees: Attendee[]): number {
    return attendees.map((a) => this.sumFeeTotal(a)).reduce((a, b) => a + b, 0);
  }

  private sumFeeTotal(a: Attendee): number {
    return (a.financeInfo?.fees ?? []).reduce(
      (total, fee) => total + fee.price,
      0
    );
  }

  totalPaid(attendees: Attendee[]): number {
    return attendees
      .map(
        (a) =>
          (a.financeInfo?.paid ?? 0) + (a.financeInfo?.balanceCorrection ?? 0)
      )
      .reduce((a, b) => a + b, 0);
  }

  debtorsCount(attendees: Attendee[]): number {
    return attendees.filter((a) => computeBalance(a.financeInfo) < 0).length;
  }

  updatePaymentsToServer() {
    this.savingToServer = true;
    this.theClient
      .updatePayments(
        this.deltas.map(([a, delta]) => {
          return [a.id, a.financeInfo!.paid + delta];
        })
      )
      .then(() => {
        this.paymentsDialog = false;
        this.savingToServer = false;
      });
  }

  creditorsCount(attendees: Attendee[]): number {
    return attendees.filter((a) => computeBalance(a.financeInfo) > 0).length;
  }

  fileProbablyCSV = false;
  fileContainsExpectedHeaders = false;
  fileProcessing = false;
  fileOpened = false;
  savingToServer = false;
  payments: Payment[] = [];
  unknownPayments: Payment[] = [];
  deltas: [Attendee, number][] = [];

  openPaymentsDialog() {
    this.fileOpened = false;
    this.fileProcessing = false;
    this.payments = [];
    this.deltas = [];
    this.paymentsDialog = true;
  }

  fileSelected(f: File) {
    if (!f) {
      this.fileOpened = false;
      return;
    }
    this.fileProbablyCSV = false;
    this.fileContainsExpectedHeaders = false;
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result?.toString() ?? "";
      const lines = text.split("\n").filter((l) => l.trim().length > 0);
      const headers = lines[0]
        .split(";")
        .map((field) => field.trim().substring(1, field.trim().length - 1));
      console.log(headers);
      if (headers.includes("Objem") && headers.includes("VS")) {
        this.fileContainsExpectedHeaders = true;
      }
      lines.splice(0, 1);

      const attendeesByVs = new Map(
        this.attendees
          .filter((a) => a.financeInfo)
          .map((a) => [a.financeInfo!.varSymbol, a])
      );
      this.payments = lines
        .map((line) => line.split(";"))
        .map((l) => {
          const payment: Payment = {};
          l.forEach((field, index) => {
            console.log(field, index, headers[index]);
            switch (headers[index]) {
              case "Objem":
                payment.amount = Number.parseFloat(
                  field.substring(1, field.length - 1)
                );
                break;
              case "VS":
                payment.vs = Number.parseInt(
                  field.substring(1, field.length - 1)
                );
                payment.attendee = attendeesByVs.get(payment.vs);
                break;
              case "Zpráva pro příjemce":
                payment.comment = field.substring(1, field.length - 1);
              default:
            }
          });
          return payment;
        });
      this.deltas = Array.from(attendeesByVs.entries())
        .map(
          ([vs, attendee]) =>
            [
              attendee,
              this.payments
                .filter((p) => p.vs == vs)
                .reduce((sum, p) => sum + p.amount!, 0) -
                attendee.financeInfo!.paid,
            ] as [Attendee, number]
        )
        .filter(([a, delta]) => delta != 0);
      this.unknownPayments = this.payments.filter(
        (p) => !(p.vs && attendeesByVs.has(p.vs))
      );
      this.fileProcessing = false;
    };
    reader.readAsText(f);
    this.fileOpened = true;
  }

  anyNegative(deltas: [Attendee, number][]): boolean {
    return deltas.find(([a, amount]) => amount < 0) ? true : false;
  }
}

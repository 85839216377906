"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var QuillContent_vue_1 = require("@/components/QuillContent.vue");
var QuillEditor_vue_1 = require("@/components/QuillEditor.vue");
var cmak_ui_1 = require("../cmak-ui");
var cmak_client_1 = require("../cmak-client");
var MotivationLetter = /** @class */ (function (_super) {
    __extends(MotivationLetter, _super);
    function MotivationLetter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.theClient = new cmak_client_1.EmittingCmakClient(cmak_ui_1.theClient, _this);
        _this.content = null;
        _this.wordCount = 0;
        _this.me = null;
        _this.registrationsAllowed = true;
        _this.experience = null;
        return _this;
    }
    MotivationLetter.prototype.mounted = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        this.me = cmak_ui_1.theStore.person;
        this.content = (_a = cmak_ui_1.theStore.person.registrationInfo) === null || _a === void 0 ? void 0 : _a.motivationalLetter;
        this.experience = (_e = (_d = (_c = (_b = cmak_ui_1.theStore.person) === null || _b === void 0 ? void 0 : _b.registrationInfo) === null || _c === void 0 ? void 0 : _c.motivationalLetter) === null || _d === void 0 ? void 0 : _d.experience) !== null && _e !== void 0 ? _e : null;
        cmak_ui_1.theConfig
            .get()
            .then(function (c) { return (_this.registrationsAllowed = c.registrationsAllowed); });
    };
    MotivationLetter.prototype.updateContent = function (change) {
        this.content = change;
        console.log(change);
        this.wordCount = cmak_ui_1.countWords(change);
    };
    MotivationLetter.prototype.saveLetter = function () {
        var _this = this;
        var _a, _b, _c;
        if (this.me) {
            var updatedRegistration = (_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.registrationInfo) !== null && _b !== void 0 ? _b : {};
            updatedRegistration.motivationalLetter = { delta: this.content, experience: (_c = this.experience) !== null && _c !== void 0 ? _c : undefined };
            // in progress = true
            this.theClient
                .updateRegistration(updatedRegistration)
                .then(function (a) { return (cmak_ui_1.theStore.person = a); })
                .then(function () {
                _this.$router.push({ path: "/main/registration" });
            });
            // .then(()=>....in progress=false)
        }
        else {
            console.log("sum-ting-wong - there is no ME");
        }
    };
    MotivationLetter = __decorate([
        vue_property_decorator_1.Component({ components: { QuillContent: QuillContent_vue_1.default, QuillEditor: QuillEditor_vue_1.default } })
    ], MotivationLetter);
    return MotivationLetter;
}(vue_property_decorator_1.Vue));
exports.default = MotivationLetter;



























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import LetterEvaluator from "./LetterEvaluator.vue";
import Evaluation from "./Evaluation.vue";
import QuillContent from "./QuillContent.vue";
import { theStore, theCmak, averageEval,isReadyForPosition } from "../cmak-ui";
import { LetterEvaluation, Attendee, Authorization } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import MotivationLetter from "@/views/MotivationLetter.vue";

@Component({ components: { LetterEvaluator, Evaluation } })
export default class UserAdminDashboard extends Vue {
  @Prop() 
  attendees!:Attendee[]
  
  count: number = 0
  registrationCount: number = 0
  orgsCount: number = 0
  
  mounted(){
    this.calculateStats(this.attendees)
  }
  
  @Watch("attendees")
  calculateStats(attendees: Attendee[]) {
    this.count=attendees.length    
    this.registrationCount = attendees.filter(isReadyForPosition ).length
    this.orgsCount = attendees.filter(a => a.authorizations?.includes(Authorization.ORGANIZER)).length    
  }
}








import { Component, Prop, Vue } from 'vue-property-decorator'
import {Client} from '../cmak-client'

@Component
export default class HelloWorld extends Vue {
  @Prop() private msg!: string;
    
}

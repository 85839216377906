












import { Vue, Component, Watch } from "vue-property-decorator";
import LetterEvaluation from "../components/LetterEvaluator.vue";
import EvaluatorDashboard from "../components/EvaluatorDashboard.vue";
import RoleAdminDashboard from "../components/RoleAdminDashboard.vue";
import UserAdminDashboard from "../components/UserAdminDashboard.vue";
import PaymentsDashboard from "../components/PaymentsDashboard.vue";
import {Authorization, Attendee} from '@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types'
import { theClient, theStore} from "../cmak-ui"
import { EmittingCmakClient} from "../cmak-client"


@Component({ components: { LetterEvaluation, EvaluatorDashboard, RoleAdminDashboard,UserAdminDashboard, PaymentsDashboard } })
export default class Admin extends Vue {
  theClient=new EmittingCmakClient(theClient,this)
  attendees:Attendee[]=[]
  mounted(){    
    if(this.isRoleAdmin()){
      this.theClient!.getAllAttendees().then(a=>this.attendees=a)
    }
  }
  isEvaluator():boolean{
    return theStore.person?.authorizations?.includes(Authorization.EVALUATOR)??false
  }
  isRoleAdmin():boolean{
    return theStore.person?.authorizations?.includes(Authorization.ADMIN)??false
  }
}

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var AddressEditor_vue_1 = require("@/components/AddressEditor.vue");
var HelloWorld_vue_1 = require("@/components/HelloWorld.vue");
var AttendeeProfile_vue_1 = require("@/components/AttendeeProfile.vue");
var cmak_ui_1 = require("./cmak-ui");
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menuVisible = false;
        _this.cmak = cmak_ui_1.theCmak;
        _this.messages = [];
        return _this;
    }
    App = __decorate([
        vue_property_decorator_1.Component({
            components: {
                AddressEditor: AddressEditor_vue_1.default, HelloWorld: HelloWorld_vue_1.default, AttendeeProfile: AttendeeProfile_vue_1.default
            }
        })
    ], App);
    return App;
}(vue_property_decorator_1.Vue));
exports.default = App;

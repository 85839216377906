import { render, staticRenderFns } from "./dailyRating.vue?vue&type=template&id=db0621c2&"
import script from "./dailyRating.vue?vue&type=script&lang=ts&"
export * from "./dailyRating.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
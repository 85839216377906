
























































































































































































































































































































import { component } from "node_modules/vue/types/umd";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  Attendee,
  Position,
  Person,
  LetterEvaluation,
  demandEstimate,
  PositionFilter,
  ApplicableFilter,
  Authorization,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import {
  theStore,
  theClient,
  averageEval,
  satisfactionEstimate,
  theCmak,
  distributePositions,
  shortPositionText,
  isReadyForPosition,
  filterToText,
} from "../cmak-ui";
import AttendeeProfile from "../components/AttendeeProfile.vue";
import RoleSelection from "../components/RoleSelection.vue";
import Evaluation from "../components/Evaluation.vue";
import FilterSelector from "../components/FilterSelector.vue";
import { CmakClient } from "@/cmak-client";
import { EmittingCmakClient } from "../cmak-client";
import QuillContent from "../components/QuillContent.vue";

class RankedAttendee {
  rank: number = 0;
  position?: Position;
  satisfaction?: number;
  averageEval: LetterEvaluation;
  blocked: boolean = false;
  constructor(public attendee: Attendee) {
    this.averageEval = averageEval(attendee.motivationLetterRatings ?? []);
    if (attendee.position) {
      this.position = attendee.position;
      this.satisfaction = satisfactionEstimate(attendee, attendee.position);
      this.blocked = true;
    }
  }
}
function sortAttendees(attendees: RankedAttendee[]) {
  attendees.sort((a1, a2) => {
    const a1Eval = a1.averageEval;
    const a2Eval = a2.averageEval;
    if (!a1Eval || !a2Eval) {
      return (a1Eval ? 1 : 0) - (a2Eval ? 1 : 0);
    }
    if (!isReadyForPosition(a1.attendee)) {
      if (!isReadyForPosition(a2.attendee)) {
        return 0;
      } else {
        return 1;
      }
    } else if (!isReadyForPosition(a2.attendee)) {
      return -1;
    }
    const compare =
      (a2Eval.creativity +
        a2Eval.grammar +
        a2Eval.motivation +
        a2Eval.karasIndex) *
        100 +
      a2Eval.creativity -
      ((a1Eval.creativity +
        a1Eval.grammar +
        a1Eval.motivation +
        a1Eval.karasIndex) *
        100 +
        a1Eval.creativity);
    return compare;
  });
}

@Component({
  components: { Evaluation, RoleSelection, FilterSelector, QuillContent },
})
export default class RoleAdmin extends Vue {
  theClient = new EmittingCmakClient(theClient, this);
  filterCategories: {
    primary: boolean;
    filters: boolean;
    assigned: boolean;
  } = {
    primary: false,
    filters: false,
    assigned: false,
  };
  filters: PositionFilter[] = [];
  filtersExpanded = false;
  dataLoading: boolean = true;
  dataSaving: boolean = false;
  demandCache!: Map<string, number>;
  rankedAttendees: RankedAttendee[] = [] as RankedAttendee[];
  attendeeDetailDialog = false;
  detailAttendee?: RankedAttendee;
  selectedPosition?: Position | null = null;
  letterExpanded = false;
  nameFilter: string = "";
  backupCandidateFilter: boolean = false;

  created() {
    this.dataLoading = true;
    this.theClient
      .getAllAttendees()
      .then((attendees) => {
        const ra = attendees.map((a) => new RankedAttendee(a));
        sortAttendees(ra);
        ra.forEach((ra, index) => (ra.rank = index + 1));
        this.rankedAttendees = ra;
      })
      .finally(() => (this.dataLoading = false));
    this.theClient
      .getPositionDemand()
      .then((demand) => (this.demandCache = demand));
  }

  usedPositions(rankedAttendees:RankedAttendee[], detailAttendee?:RankedAttendee):Position[]{
      return rankedAttendees.filter(ra=>ra.attendee.id!=detailAttendee?.attendee.id)
      .filter((a) => a.blocked)
      .map((a) => a.position!);
  }

  givePositions() {
    const wantedAttendees = this.rankedAttendees.filter((a) => !a.blocked);

    const availablePositions = [...theCmak.positions]
    this.usedPositions(this.rankedAttendees).forEach(up=>{
      const index = availablePositions.findIndex(p=>up.identifier==p.identifier)
        if(index>=0){
          availablePositions.splice(index,1)
        }
    })
    distributePositions(
      availablePositions,
      wantedAttendees,
      new Map(
        demandEstimate(
          wantedAttendees.map((ra) => ra.attendee),
          theCmak.positions
        )
      )
    );
    wantedAttendees.forEach((ra) => {
      if (ra.position) {
        ra.satisfaction = satisfactionEstimate(ra.attendee, ra.position);
      } else {
        ra.satisfaction = undefined;
      }
    });

    this.rankedAttendees = [...this.rankedAttendees];
  }
  posToText(position: Position): string {
    return position ? shortPositionText(position) : "";
  }
  filterToText(filter: PositionFilter): string {
    return filterToText(filter);
  }
  filtersToText() {
    return this.detailAttendee?.attendee.registrationInfo?.secondaryRoleFilters
      ?.map(filterToText)
      .join(", ");
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
  }

  addFilter(filter: PositionFilter) {
    console.log("new filter", filter.type, filter.value);
    if (this.isNewFilter(filter.type, filter.value)) {
      this.filters.push({ type: filter.type, value: filter.value });
    }
  }

  private isNewFilter(filterType: string, value: string): boolean {
    return (
      this.filters.filter((f) => f.type === filterType && f.value === value)
        .length === 0
    );
  }

  showDetail(attendee: RankedAttendee) {
    this.detailAttendee = attendee;
    this.attendeeDetailDialog = true;
  }

  savePosition(position: Position) {
    this.detailAttendee!.position = position;
    this.detailAttendee!.blocked = true;
    this.detailAttendee!.satisfaction = satisfactionEstimate(
      this.detailAttendee!.attendee,
      this.detailAttendee!.position
    );
  }

  isAvailable(position: Position): boolean {
    if (!position) {
      return false;
    }
    let availablePositions = [...theCmak.positions];
    this.rankedAttendees
      .filter((r) => r.blocked)
      .forEach((ra) => {
        let i = availablePositions.findIndex(
          (p) => p.identifier == ra.position?.identifier
        );
        availablePositions.splice(i, 1);
      });

    return availablePositions.find((p) => p.identifier == position.identifier)
      ? true
      : false;
  }
  
  sendToServer() {
    this.dataSaving = true;
    //change email for id after merge
    const map = new Map(
      this.rankedAttendees
        .filter((r) => r.position)
        .map((r) => [r.attendee.id, r.position!])
    );
    this.theClient.assignPositions(map).finally(() => (this.dataSaving = false));
  }

  filtered(rankedAttendees: RankedAttendee[]) {
    const applicableFilters: ApplicableFilter[] = ApplicableFilter.makeApplicable(
      this.filters
    );
    return rankedAttendees.filter((ra) => {
      if (this.filterCategories.primary) {
        if (
          !ra.attendee.registrationInfo?.primaryRoles?.filter(
            (r) => r && applicableFilters.every((f) => f.matches(r))
          ).length
        ) {
          return false;
        }
      }
      if (this.filterCategories.assigned) {
        if (
          !(
            ra.position &&
            applicableFilters.every((f) => f.matches(ra.position!))
          )
        ) {
          return false;
        }
      }
      if (this.filterCategories.filters) {
        if (
          !applicableFilters.every((f) =>
            ra.attendee.registrationInfo?.secondaryRoleFilters?.find(
              (secondaryFilter) =>
                secondaryFilter &&
                secondaryFilter.type == (f as any).type &&
                secondaryFilter.value == f.value
            )
          )
        ) {
          return false;
        }
      }
      if (this.nameFilter?.length > 0) {
        if (!(ra.attendee.name == this.nameFilter)) {
          return false;
        }
      }
      if (this.backupCandidateFilter) {
        if(!ra.attendee.backupRoleCandidate) {
          return false;
        }
      }
      return true;
    });
  }

  grantAttendees() {
    this.theClient.grantAttendeeStatus();
  }
}

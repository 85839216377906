














































































































import Vue from "vue";
import PersonHeader from "./PersonHeader.vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import {
  Attendee,
  Authorization,
  Fee,
  Services,
  FinanceInfo,
  Position,
  RegistrationInfo
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import GetUserApi from "@/components/GetUserApi.vue";
import { theClient, theStore, theConfig, shortPositionText, computeBalance } from "../cmak-ui";
import { EmittingCmakClient } from "@/cmak-client";
import FeeList from "@/components/FeeList.vue";
import QrCodeCust from "@/components/QrCodeCust.vue";

@Component({ components: { PersonHeader, FeeList, QrCodeCust } })
export default class AttendeeProfile extends Vue {
  @Prop({ required: true }) person!: Attendee;
  theClient = new EmittingCmakClient(theClient, this);
  name = this.person.name;
  primaryEmail?: string;
  rules = {
    name: [
      (val) =>
        ((val ?? "").split(" ").length > 1 &&
          (val || "").split(" ").length <= 3 &&
          val?.length > 5) ||
        "Neplatné jméno",
    ],
  };
  nameSaved: boolean = false;
  fees: Fee[] | null = null;
  toPay: number = 0;
  hideAssignedRoles:boolean | null = null

  created() {
    this.fees = this.person.financeInfo?.fees ?? null;
    this.toPay = -computeBalance(this.person.financeInfo);
    this.primaryEmail = this.person.registrationInfo?.primaryEmail ?? this.person.email;
    theConfig.get().then(c => this.hideAssignedRoles = c.hideAssignedRoles) 
  }

  checkboxMessage(status:boolean):string{
  return status? "Ano, hlásím se o náhradnický mandát." : "Zaškrtněte, pokud se chcete hlásit o náhradnický mandát."    
  }
  
  changeBackupStatus() {    
    this.theClient.updateAttendee({backupRoleCandidate:this.person.backupRoleCandidate?true:false},this.person.id)        
  }

  shortPositionText(position: Position) {
    return shortPositionText(position)
  }

  isOrganizer(person: Attendee) {
    return person.authorizations?.includes(Authorization.ORGANIZER);
  }
  isAdmin(person: Attendee) {
    return person.authorizations?.includes(Authorization.ADMIN);
  }
  isEvaluator(person: Attendee) {
    return person.authorizations?.includes(Authorization.EVALUATOR);
  }
  isAttendee(person: Attendee) {
    return person.authorizations?.includes(Authorization.ATTENDEE);
  }

  score(person: Attendee): number {
    let score = 0;
    if (person.registrationInfo) {
      score = score + 5;
    }
    if (person?.registrationInfo?.motivationalLetter) {
      score = score + 40;
    }
    if (person?.registrationInfo?.secondaryRoleFilters) {
      score = score + 10;
    }
    if (person?.registrationInfo?.primaryRoles) {
      score = score + 5;
    }
    if (person?.registrationInfo?.primaryRoles?.length == 3) {
      score = score + 20;
    }
    if (person?.registrationInfo?.primaryRoles?.every((r) => r)) {
      score = score + 20;
    }
    return score;
  }
  saveBasicInfo(){
    let registrationInfo:RegistrationInfo = theStore.person?.registrationInfo??{}
    registrationInfo.primaryEmail=this.primaryEmail;
    this.theClient.updateAttendee({name: this.name!, registrationInfo: registrationInfo}, theStore.person?.id!).then(a=>{      
      this.person = a
      theStore.person = a
    })
  }
}

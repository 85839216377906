import { BodyType, Committee, CongressCommitteeId, CongressPosition, Department, Fraction, LobbyistPosition, LobbyType, MinisterPosition, Organization, Party, Position, SenateCommitteeId, SenatePosition, State, TckPosition, OrgPosition, OrgTeam, Fee, PriceList, positionToText, RatingResponsibilities, Attendee, Authorization, CommitteeFilter, BodyFilter, calculateSingleRatingOverall, getRatingType } from './cmak-types'
// ----------------------------------------------------------------------------------------------------------------------------------

function newSenateCommittee(id: SenateCommitteeId, name: string): Committee {
  return {
    id: id,
    name: name,
    senate: true,
    congress: false,
    attendees: [],
    chairPersons: [],
    materials: [],
    agenda: []
  }
}

function newCongressCommittee(id: CongressCommitteeId, name: string): Committee {
  return {
    id: id,
    name: name,
    senate: false,
    congress: true,
    attendees: [],
    chairPersons: [],
    materials: [],
    agenda: []
  }
}

function newSenatePositions(committee: SenateCommitteeId, party: Party, fractionStates: [Fraction | undefined, State[]][]): SenatePosition[] {
  const field: SenatePosition[] = []
  fractionStates.forEach(([fraction, states]) => {
    if (!fraction) {
      throw new Error('Fraction undefined')
    }
    states.forEach(state => {
      const position: SenatePosition = {
        body: BodyType.Senate,
        committee: committee,
        party: party,
        fraction: fraction,
        state: state,
        identifier: `congress:${party}:${fraction.name}:${committee}:${state}`
      }
      field.push(position)
    })
  })
  return field
}

function newCongressPositions(committee: CongressCommitteeId, fraction: Fraction | undefined, states: State[]): CongressPosition[] {
  if (!fraction) {
    throw new Error('Fraction undefined')
  }
  return states.map(state => {
    return {
      body: BodyType.Congress,
      committee: committee,
      party: fraction.party,
      fraction: fraction,
      state: state,
      identifier: `congress:${fraction.party}:${fraction.name}:${committee}:${state}`
    }
  })
}

export function determineRatingResponsibility(attendee: Attendee): RatingResponsibilities {
  const result: RatingResponsibilities = {
    adjustment: [],
    dailyRating: [],
    visibility: []
  }
  if (attendee.authorizations?.includes(Authorization.ORGANIZER)) {
    if(attendee.organizer?.orgPosition?.committee) {
      result.dailyRating.push(new CommitteeFilter({value: attendee.organizer?.orgPosition?.committee}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
      result.adjustment.push(new CommitteeFilter({value: attendee.organizer?.orgPosition?.committee}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Lobby}))
      result.adjustment.push(new BodyFilter({value: BodyType.Lobby}))
    }
    if(attendee.organizer?.orgTeam.find(t => t.name == "Lobby") || attendee.organizer?.orgPosition?.identifier == "org.vys") {
      result.dailyRating.push(new BodyFilter({value: BodyType.Lobby}))
      result.adjustment.push(new BodyFilter({value: BodyType.Lobby}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "ekfnk.nfe") {
      result.adjustment.push(new BodyFilter({value: BodyType.Government}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.vice"){
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.cos"){
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Senate}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Congress}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.sp"
    || attendee.organizer?.orgPosition?.identifier == "org.ppt"){
      result.dailyRating.push(new BodyFilter({value: BodyType.Government}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Senate}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Congress}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.tck" || attendee.organizer?.orgPosition?.identifier == "org.whps") {
      result.adjustment.push(new BodyFilter({value: BodyType.TCK}))
      result.visibility.push(new BodyFilter({value: BodyType.TCK}))
      result.dailyRating.push(new BodyFilter({value: BodyType.TCK}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.whipd" || attendee.organizer?.orgPosition?.identifier == "org.whipr"){
      result.dailyRating.push(new BodyFilter({value: BodyType.Congress}))
      result.adjustment.push(new BodyFilter({value: BodyType.Congress}))
      result.dailyRating.push(new BodyFilter({value: BodyType.Senate}))
      result.adjustment.push(new BodyFilter({value: BodyType.Senate}))
    }
    if(attendee.organizer?.orgPosition?.identifier == "org.puke"){
      result.adjustment.push(new BodyFilter({value: BodyType.Lobby}))
    }
  }
  return result;
}

export function getCurrentRatingDay(): number {
  const currDate = new Date()

  // if (currDate.toDateString() == "Tue Sep 07 2021") {
  //   return 0;
  // }else if (currDate.toDateString() == "Wed Sep 08 2021") {
  //   return 1;
  // }else if (currDate.toDateString() == "Thu Sep 09 2021") {
  //   return 2;
  // }
  // return -1;

  if (currDate.toDateString() == "Tue Sep 06 2022") {
    return 0;
  }else if (currDate.toDateString() == "Wed Sep 07 2022") {
    return 1;
  }else if (currDate.toDateString() == "Thu Sep 08 2022") {
    return 2;
  }
  return -1;
}



/*
function newOrgPosition(
  id: string,
  committee?: SenateCommitteeId | CongressCommitteeId,
  party?: Party,
  fraction?: Fraction) {

    return
}
*/

export class CmakDefinition {
  committees: Committee[] = [
    newSenateCommittee(SenateCommitteeId.VJ, 'Výbor pro justici'),
    newSenateCommittee(SenateCommitteeId.VOS, 'Výbor pro ozbrojené složky'),
    newSenateCommittee(SenateCommitteeId.VPZ, 'Výbor pro přírodní zdroje'),
    newCongressCommittee(CongressCommitteeId.VDR, 'Výbor pro dohled a reformu'),
    newCongressCommittee(CongressCommitteeId.VEH, 'Výbor pro energetiku a hospodářství'),
    newCongressCommittee(CongressCommitteeId.VNB, 'Výbor pro národní bezpečnost'),
    newCongressCommittee(CongressCommitteeId.VSP, 'Výbor pro školství a práci'),
    newCongressCommittee(CongressCommitteeId.VZV, 'Výbor pro zahraniční vztahy'),
    newCongressCommittee(CongressCommitteeId.VVVT, 'Výbor pro vědu, vesmír a technologie')
  ];

  senateCommittees = this.committees.filter(c => c.senate);

  congressCommittees = this.committees.filter(c => c.congress);

  // fractions = new Map<String, Fraction>([
  //   ["LD", {
  //     name: 'Liberální republikáni',
  //     party: Party.Republican
  //   }],

  // ])
  governingParty: Party = Party.Democrat
  departments: Department[] = [
    {
      id: 'FN',
      name: 'Finance',
      availableCommittees: [CongressCommitteeId.VEH, SenateCommitteeId.VJ]
    },
    {
      id: 'OD',
      name: 'Obchod',
      availableCommittees: [CongressCommitteeId.VEH, CongressCommitteeId.VZV, CongressCommitteeId.VDR]
    },
    {
      id: 'NB',
      name: 'Národní bezpečnost',
      availableCommittees: [CongressCommitteeId.VNB]
    },
    {
      id: 'SK',
      name: 'Vědy a technologií',
      availableCommittees: [CongressCommitteeId.VSP, CongressCommitteeId.VVVT]
    },
    {
      id: 'EN',
      name: 'Práce',
      availableCommittees: [CongressCommitteeId.VSP, CongressCommitteeId.VVVT]
    },
    {
      id: 'ZA',
      name: 'Zahraničí',
      availableCommittees: [CongressCommitteeId.VZV, SenateCommitteeId.VPZ]
    },
    {
      id: 'OB',
      name: 'Obrana',
      availableCommittees: [SenateCommitteeId.VOS, CongressCommitteeId.VZV]
    },
    {
      id: 'EG',
      name: 'Energetika',
      availableCommittees: [SenateCommitteeId.VPZ, CongressCommitteeId.VEH, CongressCommitteeId.VNB]
    },
    {
      id: 'SP',
      name: 'Spravedlnost',
      availableCommittees: [SenateCommitteeId.VJ, CongressCommitteeId.VDR]
    }
  ]

  fractions: Fraction[] = [
    {
      id: 'LIBREP',
      name: 'Liberální republikáni',
      party: Party.Republican
    },

    {
      id: 'NOVPRAV',
      name: 'Nová pravice',
      party: Party.Republican
    },
    {
      id: 'KLSVOB',
      name: 'Klub za svobodu',
      party: Party.Republican
    },
    {
      id: 'TRAD',
      name: 'Tradicionalisté',
      party: Party.Republican
    },
    {
      id: 'DEMSOC',
      name: 'Demokratičtí socialisté',
      party: Party.Democrat
    },
    {
      id: 'PROG',
      name: 'Progresivisté',
      party: Party.Democrat
    },
    {
      id: 'NOVDEM',
      name: 'Noví demokraté',
      party: Party.Democrat
    },
    {
      id: 'MODRY',
      name: 'Modří psi',
      party: Party.Democrat
    }
  ]

  organizations: Organization[] = [
    {
      id: 'AFL',
      name: 'AFL-CIO',
      type: LobbyType.Un
    },
    {
      id: 'TMS',
      name: 'Teamsters',
      type: LobbyType.Un
    },
    {
      id: 'SEIN',
      name: 'Service Employees International Union',
      type: LobbyType.Un
    },
    {
      id: 'NEAUSA',
      name: 'National Education Association of the United States',
      type: LobbyType.Un
    },
    {
      id: 'AMAZ',
      name: 'Amazon',
      type: LobbyType.Corp
    },
    {
      id: 'SPCX',
      name: 'Tesla/SpaceX',
      type: LobbyType.Corp
    },
    {
      id: 'GEND',
      name: 'General Dynamics',
      type: LobbyType.Corp
    },
    {
      id: 'ACDM',
      name: 'Academy',
      type: LobbyType.Corp
    },
    {
      id: 'GGL',
      name: 'Google',
      type: LobbyType.Corp
    },
    {
      id: 'NSDQ',
      name: 'Nasdaq',
      type: LobbyType.Corp
    },
    {
      id: 'FLUOR',
      name: 'Fluor Corporation',
      type: LobbyType.Corp
    },
    {
      id: 'WALM',
      name: 'Walmart',
      type: LobbyType.Corp
    },
    {
      id: 'CHEV',
      name: 'Chevron',
      type: LobbyType.Corp
    },
    {
      id: 'GEO',
      name: 'GEO Group, Inc.',
      type: LobbyType.Corp
    },
    {
      id: 'PFIZ',
      name: 'Pfizer',
      type: LobbyType.Corp
    },
    {
      id: 'INTEL',
      name: 'Intel',
      type: LobbyType.Corp
    },
    {
      id: 'WF',
      name: 'Wells Fargo',
      type: LobbyType.Corp
    },
    {
      id: 'MUSL',
      name: 'US Council of Muslim Organisations',
      type: LobbyType.Np
    },
    {
      id: 'WHEAT',
      name: 'Wheaton College',
      type: LobbyType.Np
    },
    {
      id: 'GATES',
      name: 'Gates Foundation',
      type: LobbyType.Np
    },
    {
      id: 'SOROS',
      name: 'Open Society Foundation',
      type: LobbyType.Np
    },
    {
      id: 'REPWOM',
      name: 'Represent Women',
      type: LobbyType.Np
    },
  ]

  orgsById = new Map<string, Organization>(
    this.organizations.map(o => [o.id, o])
  );

  fractionsById = new Map<string, Fraction>(
    this.fractions.map(f => [f.id, f])
  );

  departmentsById = new Map<string, Department>(
    this.departments.map(d => [d.id, d])
  );

  committeesById = new Map<string, Committee>(
    this.committees.map(c => [c.id, c])
  );

  senatePositions: SenatePosition[] = [
    ...newSenatePositions(SenateCommitteeId.VJ, Party.Republican, [
      [this.fractionsById.get('LIBREP'), [State.NorthernCarolina]],
      [this.fractionsById.get('NOVPRAV'), [State.Nebraska, State.SouthernCarolina, State.Iowa]],
      [this.fractionsById.get('KLSVOB'), [State.Lousiana, State.Texas]],
      [this.fractionsById.get('TRAD'), [State.Utah]]
    ]),
    ...newSenatePositions(SenateCommitteeId.VJ, Party.Democrat,
      [
        [this.fractionsById.get('DEMSOC'), [State.California]],
        [this.fractionsById.get('PROG'), [State.NewJersey, State.Minnesota]],
        [this.fractionsById.get('NOVDEM'), [State.Illinois, State.Delaware, State.Georgia]],
        [this.fractionsById.get('MODRY'), [State.Connecticut]]
      ]),
    ...newSenatePositions(SenateCommitteeId.VOS, Party.Republican,
      [
        [this.fractionsById.get('LIBREP'), [State.Pennsylvania]],
        [this.fractionsById.get('NOVPRAV'), [State.Mississippi, State.SouthernDakota, State.Florida]],
        [this.fractionsById.get('KLSVOB'), [State.Alabama, State.Tennessee]],
        [this.fractionsById.get('TRAD'), [State.Oklahoma]]
      ]),
    ...newSenatePositions(SenateCommitteeId.VOS, Party.Democrat,
      [
        [this.fractionsById.get('DEMSOC'), [State.NewYork]],
        [this.fractionsById.get('PROG'), [State.Massachusetts, State.Hawaii]],
        [this.fractionsById.get('NOVDEM'), [State.RhodeIsland, State.Arizona, State.Virginia]],
        [this.fractionsById.get('MODRY'), [State.WesternVirginia]]
      ]),
    ...newSenatePositions(SenateCommitteeId.VPZ, Party.Republican, [
      [this.fractionsById.get('LIBREP'), [State.Alaska]],
      [this.fractionsById.get('NOVPRAV'), [State.Wyoming, State.Idaho, State.NorthernDakota]],
      [this.fractionsById.get('KLSVOB'), [State.Kansas, State.Montana]],
      [this.fractionsById.get('TRAD'), [State.Kentucky]]
    ]),
    ...newSenatePositions(SenateCommitteeId.VPZ, Party.Democrat,
      [
        [this.fractionsById.get('DEMSOC'), [State.Vermont]],
        [this.fractionsById.get('PROG'), [State.Nevada, State.Oregon]],
        [this.fractionsById.get('NOVDEM'), [State.Washington, State.Arkansas, State.NewMexico]],
        [this.fractionsById.get('MODRY'), [State.Colorado]]
      ]),
  ];

  congressPositions: CongressPosition[] = [
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('LIBREP'), [State.Nebraska]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('NOVPRAV'), [State.Kentucky, State.SouthernCarolina]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('KLSVOB'), [State.Wisconsin, State.Arizona]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('TRAD'), [State.NorthernCarolina]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('DEMSOC'), [State.NewYork]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('PROG'), [State.Maryland, State.Vermont, State.Georgia]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('NOVDEM'), [State.Massachusetts, State.Illinois, State.Florida]),
    ...newCongressPositions(CongressCommitteeId.VDR, this.fractionsById.get('MODRY'), [State.Tennessee]),

    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('LIBREP'), [State.NorthernDakota]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('NOVPRAV'), [State.Utah, State.Indiana]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('KLSVOB'), [State.Washington, State.Alabama]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('TRAD'), [State.WesternVirginia]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('DEMSOC'), [State.Michigan]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('PROG'), [State.Colorado, State.Vermont, State.Delaware]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('NOVDEM'), [State.Illinois, State.California, State.Texas]),
    ...newCongressPositions(CongressCommitteeId.VEH, this.fractionsById.get('MODRY'), [State.Oregon]),

    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('LIBREP'), [State.NewJersey]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('NOVPRAV'), [State.Lousiana, State.Iowa]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('KLSVOB'), [State.Georgia, State.SouthernCarolina]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('TRAD'), [State.Tennessee]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('DEMSOC'), [State.Texas]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('PROG'), [State.Missouri, State.RhodeIsland, State.California]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('NOVDEM'), [State.Michigan, State.NewJersey, State.Maine]),
    ...newCongressPositions(CongressCommitteeId.VNB, this.fractionsById.get('MODRY'), [State.Mississippi]),

    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('LIBREP'), [State.California]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('NOVPRAV'), [State.NewYork, State.Pennsylvania]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('KLSVOB'), [State.Idaho, State.Indiana]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('TRAD'), [State.Michigan]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('DEMSOC'), [State.NewYork]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('PROG'), [State.Connecticut, State.Washington, State.NewMexico]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('NOVDEM'), [State.Hawaii, State.Delaware, State.NorthernCarolina]),
    ...newCongressPositions(CongressCommitteeId.VSP, this.fractionsById.get('MODRY'), [State.Indiana]),

    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('LIBREP'), [State.Ohio]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('NOVPRAV'), [State.Oklahoma, State.California]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('KLSVOB'), [State.Alabama, State.Texas]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('TRAD'), [State.Indiana]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('DEMSOC'), [State.NewYork]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('PROG'), [State.Wisconsin, State.Virginia, State.Oregon]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('NOVDEM'), [State.Colorado, State.Pennsylvania, State.Florida]),
    ...newCongressPositions(CongressCommitteeId.VVVT, this.fractionsById.get('MODRY'), [State.NewJersey]),

    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('LIBREP'), [State.Michigan]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('NOVPRAV'), [State.Ohio, State.Missouri]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('KLSVOB'), [State.Colorado, State.Pennsylvania]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('TRAD'), [State.Kentucky]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('DEMSOC'), [State.Minnesota]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('PROG'), [State.RhodeIsland, State.Nevada, State.NewJersey]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('NOVDEM'), [State.Massachusetts, State.Pennsylvania, State.Virginia]),
    ...newCongressPositions(CongressCommitteeId.VZV, this.fractionsById.get('MODRY'), [State.California]),
  ];

  governmentPositions: MinisterPosition[] = this.departments.map(d => {
    return {
      party: this.governingParty,
      body: BodyType.Government,
      department: d,
      availableCommittees: [],
      identifier: `government:${d.id}`
    }
  });

  lobbyistPositions: LobbyistPosition[] = this.organizations.map(o => {
    return {
      body: BodyType.Lobby,
      availableCommittees: [],
      organization: o,
      identifier: `lobby:${o.id}`
    }
  }).concat([
    {
      body: BodyType.Lobby,
      availableCommittees: [],
      organization: {
        id: 'AFL',
        name: 'AFL-CIO',
        type: LobbyType.Un
      },
      identifier: 'lobby:AFL'
    }
  ]);


  orgPositions: OrgPosition[] = [
    {
      name: "POTUS",
      body: BodyType.Other,
      identifier: "ekfnk.nfe",
      party: Party.Democrat
    },
    {
      name: "Viceprezident",
      body: BodyType.Other,
      identifier: "org.vice",
      party: Party.Democrat
    },
    {
      name: "Chief of Staff",
      body: BodyType.Other,
      identifier: "org.cos",
      party: Party.Democrat
    },
    {
      name: "National Security Advisor",
      body: BodyType.Other,
      identifier: "org.nsa",
      party: Party.Democrat
    },
    {
      name: "U.S. Attorney",
      body: BodyType.Other,
      identifier: "org.puke"
    },
    {
      name: "Assistant U.S. Attorney",
      body: BodyType.Other,
      identifier: "org.vys"
    },
    {
      name: "White House Press Secretary",
      body: BodyType.Other,
      identifier: "org.whps"
    },
    {
      name: "TCK",
      body: BodyType.TCK,
      identifier: "org.tck"
    },
    {
      name: "Fotograf",
      body: BodyType.Other,
      identifier: "org.foto"
    },
    {
      name: "Sekretariát Kongresu",
      body: BodyType.Other,
      identifier: "org.sekkon"
    },
    {
      name: "President pro tempore",
      body: BodyType.Senate,
      identifier: "org.ppt",
      party: Party.Democrat,
      state: State.Maine
    },
    {
      name: "Předsedající VPZ za DEM-DEMSOC",
      body: BodyType.Senate,
      identifier: "org.vpz.demsoc",
      party: Party.Democrat,
      fraction: this.fractionsById.get('DEMSOC'),
      state: State.Ohio,
      committee: SenateCommitteeId.VPZ
    },
    {
      name: "Předsedající VPZ za REP-KLSVOB",
      body: BodyType.Senate,
      identifier: "org.vpz.klsvob",
      party: Party.Republican,
      fraction: this.fractionsById.get('KLSVOB'),
      state: State.Kansas,
      committee: SenateCommitteeId.VPZ
    },
    {
      name: "Předsedající VOS za DEM-NOVDEM",
      body: BodyType.Senate,
      identifier: "org.vos.novdem",
      party: Party.Democrat,
      fraction: this.fractionsById.get('NOVDEM'),
      state: State.Michigan,
      committee: SenateCommitteeId.VOS
    },
    {
      name: "Předsedající VOS za REP-NOVPRAV",
      body: BodyType.Senate,
      identifier: "org.vos.novprav",
      party: Party.Republican,
      fraction: this.fractionsById.get('NOVPRAV'),
      state: State.Missouri,
      committee: SenateCommitteeId.VOS
    },
    {
      name: "Předsedající VJ za DEM-PROG",
      body: BodyType.Senate,
      identifier: "org.vj.prog",
      party: Party.Democrat,
      fraction: this.fractionsById.get('PROG'),
      state: State.Wisconsin,
      committee: SenateCommitteeId.VJ
    },
    {
      name: "Předsedající VJ za REP-TRAD",
      body: BodyType.Senate,
      identifier: "org.vj.trad",
      party: Party.Republican,
      fraction: this.fractionsById.get('TRAD'),
      state: State.Indiana,
      committee: SenateCommitteeId.VJ
    },
    {
      name: "Speaker",
      body: BodyType.Congress,
      identifier: "org.sp",
      party: Party.Democrat,
      state: State.Maryland
    },
    {
      name: "Demokratický whip",
      body: BodyType.Congress,
      identifier: "org.whipd",
      party: Party.Democrat,
      state: State.SouthernCarolina,
      fraction: this.fractionsById.get('PROG')
    },
    {
      name: "Republikánský whip",
      body: BodyType.Congress,
      identifier: "org.whipr",
      party: Party.Republican,
      fraction: this.fractionsById.get('TRAD'),
      state: State.Virginia
    },
    {
      name: "Předsedající VZV za DEM-NOVDEM",
      body: BodyType.Congress,
      identifier: "org.vzv.novdem",
      party: Party.Democrat,
      fraction: this.fractionsById.get('NOVDEM'),
      state: State.NewYork,
      committee: CongressCommitteeId.VZV
    },
    {
      name: "Předsedající VZV za DEM-MODRY",
      body: BodyType.Congress,
      identifier: "org.vzv.modry",
      party: Party.Democrat,
      fraction: this.fractionsById.get('MODRY'),
      state: State.Illinois,
      committee: CongressCommitteeId.VZV
    },
    {
      name: "Předsedající VZV za REP-TRAD",
      body: BodyType.Congress,
      identifier: "org.vzv.trad",
      party: Party.Republican,
      fraction: this.fractionsById.get('TRAD'),
      state: State.Texas,
      committee: CongressCommitteeId.VZV
    },
    {
      name: "Předsedající VNB za DEM-NOVDEM",
      body: BodyType.Congress,
      identifier: "org.vnb.novdem",
      party: Party.Democrat,
      fraction: this.fractionsById.get('NOVDEM'),
      state: State.Florida,
      committee: CongressCommitteeId.VNB
    },
    {
      name: "Předsedající VNB za DEM-MODRY",
      body: BodyType.Congress,
      identifier: "org.vnb.modry",
      party: Party.Democrat,
      fraction: this.fractionsById.get('MODRY'),
      state: State.Virginia,
      committee: CongressCommitteeId.VNB
    },
    {
      name: "Předsedající VNB za REP-LIBREP",
      body: BodyType.Congress,
      identifier: "org.vnb.librep",
      party: Party.Republican,
      fraction: this.fractionsById.get('LIBREP'),
      state: State.NewYork,
      committee: CongressCommitteeId.VNB
    },
    {
      name: "Předsedající VEH za DEM-MODRY",
      body: BodyType.Congress,
      identifier: "org.veh.modry",
      party: Party.Democrat,
      fraction: this.fractionsById.get('MODRY'),
      state: State.Arizona,
      committee: CongressCommitteeId.VEH
    },
    {
      name: "Předsedající VEH za DEM-PROG",
      body: BodyType.Congress,
      identifier: "org.veh.prog",
      party: Party.Democrat,
      fraction: this.fractionsById.get('PROG'),
      state: State.NewJersey,
      committee: CongressCommitteeId.VEH
    },
    {
      name: "Předsedající VEH za REP-LIBREP",
      body: BodyType.Congress,
      identifier: "org.veh.librep",
      party: Party.Republican,
      fraction: this.fractionsById.get('LIBREP'),
      state: State.Michigan,
      committee: CongressCommitteeId.VEH
    },
    {
      name: "Předsedající VVVT za DEM-NOVDEM",
      body: BodyType.Congress,
      identifier: "org.vvvt.novdem",
      party: Party.Democrat,
      fraction: this.fractionsById.get('NOVDEM'),
      state: State.NorthernCarolina,
      committee: CongressCommitteeId.VVVT
    },
    {
      name: "Předsedající VVVT za DEM-DEMSOC",
      body: BodyType.Congress,
      identifier: "org.vvvt.demsoc",
      party: Party.Democrat,
      fraction: this.fractionsById.get('DEMSOC'),
      state: State.Texas,
      committee: CongressCommitteeId.VVVT
    },
    {
      name: "Předsedající VVVT za REP-NOVPRAV",
      body: BodyType.Congress,
      identifier: "org.vvvt.novprav",
      party: Party.Republican,
      fraction: this.fractionsById.get('NOVPRAV'),
      state: State.Oklahoma,
      committee: CongressCommitteeId.VVVT
    },
    {
      name: "Předsedající VŠP za DEM-PROG",
      body: BodyType.Congress,
      identifier: "org.vsp.prog",
      party: Party.Democrat,
      fraction: this.fractionsById.get('PROG'),
      state: State.Oregon,
      committee: CongressCommitteeId.VSP
    },
    {
      name: "Předsedající VŠP za DEM-NOVDEM",
      body: BodyType.Congress,
      identifier: "org.vsp.novdem",
      party: Party.Democrat,
      fraction: this.fractionsById.get('NOVDEM'),
      state: State.Virginia,
      committee: CongressCommitteeId.VSP
    },
    {
      name: "Předsedající VŠP za REP-KLSVOB",
      body: BodyType.Congress,
      identifier: "org.vsp.klsvob",
      party: Party.Republican,
      fraction: this.fractionsById.get('KLSVOB'),
      state: State.NorthernCarolina,
      committee: CongressCommitteeId.VSP
    },
    {
      name: "Předsedající VDR za DEM-PROG",
      body: BodyType.Congress,
      identifier: "org.vdr.prog",
      party: Party.Democrat,
      fraction: this.fractionsById.get('PROG'),
      state: State.California,
      committee: CongressCommitteeId.VDR
    },
    {
      name: "Předsedající VDR za DEM-DEMSOC",
      body: BodyType.Congress,
      identifier: "org.vdr.demsoc",
      party: Party.Democrat,
      fraction: this.fractionsById.get('DEMSOC'),
      state: State.Missouri,
      committee: CongressCommitteeId.VDR
    },
    {
      name: "Předsedající VDR za REP-NOVPRAV",
      body: BodyType.Congress,
      identifier: "org.vdr.novprav",
      party: Party.Republican,
      fraction: this.fractionsById.get('NOVPRAV'),
      state: State.Ohio,
      committee: CongressCommitteeId.VDR
    },
    {
      name: "Tematizace",
      body: BodyType.Other,
      identifier: "org.tema"
    }
  ]

  positions: Position[] = [
    ...this.senatePositions,
    ...this.congressPositions,
    ...this.governmentPositions,
    ...this.lobbyistPositions,
    ...new Array(12).fill({ body: BodyType.TCK, identifier: 'TCK' } as TckPosition)
  ];


  positionsById = new Map<string, Position>(
    this.positions.map(p => [p.identifier, p])
  )

  orgBranches = new Map<string, string>([
    ['PRO', 'Programová a finanční sekce'],
    ['ODB', 'Odborná sekce'],
    ['ADM', 'Administrativní sekce'],
    ['PR', 'PR sekce'],
    ['ORG', 'Organizační sekce']
  ])

  orgTeams: OrgTeam[] = [
    {
      name: 'Fundrasing',
      branch: this.orgBranches.get("PRO")!
    },
    {
      name: 'Program a hosté',
      branch: this.orgBranches.get("PRO")!
    },
    {
      name: 'NSC',
      branch: this.orgBranches.get("PRO")!
    },
    {
      name: 'Simulace SCOTUS',
      branch: this.orgBranches.get("PFO")!
    },
    {
      name: 'Tematizace',
      branch: this.orgBranches.get("ODB")!
    },
    {
      name: 'Jazykové korektury',
      branch: this.orgBranches.get("ODB")!
    },
    {
      name: 'Odborné korektury',
      branch: this.orgBranches.get("ODB")!
    },
    {
      name: 'Zákonné předlohy',
      branch: this.orgBranches.get("ODB")!
    },
    {
      name: 'Jednací řád',
      branch: this.orgBranches.get("ODB")!
    },
    {
      name: 'Lobby',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'TCK',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'Hodnocení',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'Teambuildingy',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'PubQuiz',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'Vzdělávání organizátorů',
      branch: this.orgBranches.get("ADM")!
    },
    {
      name: 'Sociální sítě',
      branch: this.orgBranches.get("PR")!
    },
    {
      name: 'IT', // Superior
      branch: this.orgBranches.get("PR")! // State in the state
    },
    {
      name: 'Vnější komunikace',
      branch: this.orgBranches.get("PR")!
    },
    {
      name: 'Registrace',
      branch: this.orgBranches.get("ORG")!
    },
    {
      name: 'Koleje',
      branch: this.orgBranches.get("ORG")!
    },
    {
      name: 'Obědy',
      branch: this.orgBranches.get("ORG")!
    },
    {
      name: 'Provoz',
      branch: this.orgBranches.get("ORG")!
    }
  ]

  priceList: PriceList = {
    night: 150,
    lunch: 350,
    transport: 100,
    participation: 300
  }
  orgPriceList: PriceList = {
    night: 0,
    lunch: 0,
    transport: 0,
    participation: 0
  }

  goodNewsEmailText(position: Position): string {

    return `Hezký den vespolek z Kongresu!

    Od doby, kdy naši milovanou Ameriku zachvátily volby, uběhla už nějaká doba. Každoroční obměna mandátů, jak už to u nás v Kongresu chodí, se neobešla bez krutého boje o místa v senátu i ve sněmovně. O pozornost a hlasy soutěžilo víc než dost zástupců, a tak se každý, bohužel, nemohl stát vítězem.
    V tuto dobu také různé organizace mají tendenci měnit své zástupce, které vysílají na půdu Kongresu, aby jim vyhádali co možná nejlepší podmínky pro věc, za kterou jsou ochotné zaplatit nemalý peníz.
    V neposlední řadě i novináři jsou po sledování celého systému úplatků, manipulace a zákulisního jednání znavení a i na ně tedy přišla řada.

    Ať už jste tedy kandidovali do senátu či sněmovny, prali se o možnost zastupovat neziskovku, konglomerát nebo jinou organizaci, anebo usilovali o práci hlídacího psa demokracie, tedy novináře, nyní je nám ctí vám oznámit, že na letošním Českém modelu amerického kongresu budete zastávat tuto pozici:

    ${positionToText(position, this.committeesById)}


    Víme, že boj byl krutý, avšak i přes to jsme se snažili vyjít vstříc všem vašim požadavkům. Doufáme, že se zastávanou rolí budete spokojeni a nebudeme nám nic zazlívat – přeci jen celý ČMAK je o umění vžít se do role a vyzkoušet si to, co normálně nemůžete.

    No, a co dál? Teď už zbývá pouze přihlásit se do registračního systému, vyplnit pár dalších údajů, bez kterých se na ČMAKu neobejdeme, a případně objednat servis (ubytování, stravování apod.). Servis můžete objednávat do 12. června 2022, avšak se nebudeme zlobit, když nás svou objednávkou poctíte co nejdříve (ať se registrační tým nenudí).


    V tuto chvíli je to od nás z Kongresu vše! Kdybyste měli jakýkoliv dotaz, neváhejte se na nás obrátit na našem emailu (registrace@americkykongres.cz). Stejně tak nám dejte vědět, pokud by vám cokoliv v účasti na ČMAKu nakonec bránilo. Nemusíte se ničeho bát. 😄


    PS: ČMAK se bude konat v termínu 4. – 9. září 2022. Zapište si toto datum do deníčku, ať na něj nezapomenete!



    Srdečně vám třeseme pravicí a těšíme se na vás v záři v Plzni!



    organizační tým

    Český model amerického kongresu 2022



    tel.: +420 792 343 993`
  }
  goodNewsEmailHtml(position: Position): string {
    return `<html>
    <body>
      <p>Hezký den vespolek z Kongresu!</p>
      <br />
      <p>
        Od doby, kdy naši milovanou Ameriku zachvátily volby, uběhla už nějaká
        doba. Každoroční obměna mandátů, jak už to u nás v Kongresu chodí, se
        neobešla bez krutého boje o místa v senátu i ve sněmovně. O pozornost a
        hlasy soutěžilo víc než dost zástupců, a tak se každý, bohužel, nemohl
        stát vítězem.
      </p>
      <p>
        V tuto dobu také různé organizace mají tendenci měnit své zástupce, které
        vysílají na půdu Kongresu, aby jim vyhádali co možná nejlepší podmínky pro
        věc, za kterou jsou ochotné zaplatit nemalý peníz.
      </p>
      <p>
        V neposlední řadě i novináři jsou po sledování celého systému úplatků,
        manipulace a zákulisního jednání znavení a i na ně tedy přišla řada.
      </p>
      <br />
      <p>
        Ať už jste tedy kandidovali do senátu či sněmovny, prali se o možnost
        zastupovat neziskovku, konglomerát nebo jinou organizaci, anebo usilovali
        o práci hlídacího psa demokracie, tedy novináře, nyní je nám ctí vám
        oznámit, že na letošním Českém modelu amerického kongresu <b>budete zastávat
        tuto pozici:</b>
      </p>
      <br />
      <h2>${positionToText(position, this.committeesById)}</h2>
      <br />
      <p>
        Víme, že boj byl krutý, avšak i přes to jsme se snažili vyjít vstříc všem
        vašim požadavkům. Doufáme, že se zastávanou rolí budete spokojeni a
        nebudeme nám nic zazlívat – přeci jen celý ČMAK je o umění vžít se do role
        a vyzkoušet si to, co normálně nemůžete.
      </p>
      <br />
      <p>
        No a co dál? Teď už zbývá pouze přihlásit se do <a href="https://registace.americkykongres.cz/">registračního systému<a>,
        vyplnit pár dalších údajů, bez kterých se na ČMAKu neobejdeme, a případně
        <b>objednat servis</b> (ubytování, stravování apod.). Servis můžete objednávat do
        <b>12. června 2022</b>, avšak se nebudeme zlobit, když nás svou objednávkou
        poctíte co nejdříve (ať se registrační tým nenudí).
      </p>
      <br />

      <p>
        V tuto chvíli je to od nás z Kongresu vše! Kdybyste měli jakýkoliv dotaz,
        neváhejte se na nás obrátit na našem emailu
        (<a href="mailto:registrace@americkykongres.cz">registrace@americkykongres.cz</a>). Stejně tak nám dejte vědět, pokud by vám
        cokoliv v účasti na ČMAKu nakonec bránilo. Nemusíte se ničeho bát. 😄
      </p>
      <br />

      <p>
        PS: ČMAK se bude konat v termínu <b>4. – 9. září 2022</b>. Zapište si toto datum
        do deníčku, ať na něj nezapomenete!
      </p>
      <br />

      <p>Srdečně vám třeseme pravicí a těšíme se na vás v záři v Plzni!</p>

      <p>organizační tým</p>

      <p>Český model amerického kongresu 2022</p>
      <br />

      <p>tel.: +420 792 343 993</p>

      <p><a href="mailto:registrace@americkykongres.cz">registrace@americkykongres.cz</a></p>
    </body>
  </html>
  `
  }

  badNewsEmailText(): string {
    return `Hezký den vespolek z Kongresu!

    Od doby, kdy naši milovanou Ameriku zachvátily volby, uběhla už nějaká doba. Každoroční obměna mandátů, jak už to u nás v Kongresu chodí, se neobešla bez krutého boje o místa v senátu i ve sněmovně. O pozornost a hlasy soutěžilo víc než dost zástupců, a tak se každý, bohužel, nemohl stát vítězem.
    V tuto dobu také různé organizace mají tendenci měnit své zástupce, které vysílají na půdu Kongresu, aby jim vyhádali co možná nejlepší podmínky pro věc, za kterou jsou ochotné zaplatit nemalý peníz.
    V neposlední řadě i novináři jsou po sledování celého systému úplatků, manipulace a zákulisního jednání znavení a i na ně tedy přišla řada.

    Letos se nám v registracích na ČMAK sešlo mnoho výborných motivačních dopisů, mezi kterými byl i ten váš. Po důkladném zhodnocení všech motivačních dopisů jsem ale došli k závěru, že někteří účastníci, kteří se nakonec umístili v pomyslném žebříčku před vámi, byli o chlup lepší. V některých případech o rozdílu rozhodovaly pouze setiny bodu!

    Ač nás to velmi mrzí, v tuto chvíli vám musíme oznámit, že vám nebyla přidělena žádná role.

    Co to pro vás znamená? Nejprve vám chceme oznámit, že ještě není vše ztraceno! Stejně tak jako demokraté vidí naději v novém prezidentovi, vy můžete vidět naději v náhradnických mandátech.
    Co to znamená "náhradnický mandát"? V registračním systému v osobním profilu (po kliknutí na email v menu) najdete speciální tlačítko a kliknutím na něj nám dáváte vědět, že máte o náhradnický mandát zájem. Pak už zbývá jen čekat a doufat, že se na vás usměje štěstí a někdo svou účast na projektu zruší. Náhradnické mandáty budeme přidělovat ihned, když se někdo odhlásí – největší “vlnu” přidělování mandátů můžete čekat pravděpodobně začátkem července.

    Pokud svou budoucnost v náhradnických mandátech vidíte černě, nezbývá nám nic jiného než se s vámi rozloučit a popřát vám hodně štěstí ve vašich dalších aktivitách. Doufáme, že kvůli tomuto rozhodnutí na ČMAK nezanevřete a že se v Plzni uvidíme třeba příští rok!



    Hodně úspěchů, motivace a energie a hezké prožití léta přeje



    organizační tým

    Český model amerického kongresu 2022



    tel.: +420 792 343 993

    registrace@americkykongres.cz`
  }

  badNewsEmailHtml(): string {
    return `<html>

    <body>
        <p>Hezký den vespolek z Kongresu!</p>
        <br />
        <p>
            Od doby, kdy naši milovanou Ameriku zachvátily volby, uběhla už nějaká
            doba. Každoroční obměna mandátů, jak už to u nás v Kongresu chodí, se
            neobešla bez krutého boje o místa v senátu i ve sněmovně. O pozornost a
            hlasy soutěžilo víc než dost zástupců, a tak se každý, bohužel, nemohl
            stát vítězem.
        </p>
        <p>
            V tuto dobu také různé organizace mají tendenci měnit své zástupce, které
            vysílají na půdu Kongresu, aby jim vyhádali co možná nejlepší podmínky pro
            věc, za kterou jsou ochotné zaplatit nemalý peníz.
        </p>
        <p>
            V neposlední řadě i novináři jsou po sledování celého systému úplatků,
            manipulace a zákulisního jednáni znavení a i na ně tedy přišla řada.
        </p>
        <br />
        <p>
            Letos se nám v registracích na ČMAK sešlo mnoho výborných motivačních dopisů, mezi kterými byl i ten váš. Po
            důkladném zhodnocení všech motivačních dopisů jsem ale došli k závěru, že někteří účastníci, kteří se nakonec
            umístili v pomyslném žebříčku před vámi, byli o chlup lepší. V některých případech o rozdílu rozhodovaly pouze
            setiny bodu!</b>
        </p>
        <br />
        <p>
            Ač nás to velmi mrzí, v tuto chvíli vám musíme oznámit, že vám <b>nebyla přidělena žádná role</b>.</p>
        <p>Co to pro vás znamená? Nejprve vám chceme oznámit, že ještě není vše ztraceno! Stejně tak jako demokraté vidí
            naději v novém prezidentovi, vy můžete vidět naději v <b>náhradnických mandátech</b>.</p>
        <p>Co to znamená "náhradnický mandát"? V <a href="https://registrace.americkykongres.cz/">registračním
                systému</a> v osobním profilu (po kliknutí na email v menu) najdete
            speciální
            tlačítko a kliknutím na něj nám dáváte vědět, že máte o náhradnický mandát zájem. Pak už zbývá jen čekat a
            doufat, že se na vás usměje štěstí a někdo svou účast na projektu zruší. Náhradnické mandáty budeme přidělovat ihned, když se někdo odhlásí – největší “vlnu” přidělování mandátů můžete čekat pravděpodobně začátkem července.</p>
        <br>
        <p>Pokud svou budoucnost v náhradnických mandátech vidíte černě, nezbývá nám nic jiného než se s vámi rozloučit a
            popřát vám hodně štěstí ve vašich dalších aktivitách. Doufáme, že kvůli tomuto rozhodnutí na ČMAK nezanevřete a
            že se v Plzni uvidíme třeba příští rok!</p>

        <p>organizační tým</p>

        <p>Český model amerického kongresu 2022</p>
        <br />

        <p>tel.: +420 792 343 993</p>

        <p><a href="mailto:registrace@americkykongres.cz">registrace@americkykongres.cz</a></p>
    </body>

    </html>`
  }
}
import {
  User,
  Person,
  Attendee,
  Rating,
  RegistrationInfo,
  Position,
  BodyType,
  LobbyistPosition,
  MinisterPosition,
  CongressPosition,
  SenatePosition,
  PositionFilter,
  LetterEvaluation,
  Letter,
  Address,
  Services,
  PersonalInfo
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";

import API from "@aws-amplify/api";

export interface CmakClient {
  /**
   * returns logged in user
   */

  getMe(): Promise<Attendee>;

  /**
   * update registration info of the logged in user
   * param registration
   */
  updateRegistration(registration: RegistrationInfo): Promise<Attendee>;

  /**
   * update services info of the logged in user
   * param registration
   */
  updateServices(services: Services): Promise<Attendee>;

  updateName(name: string): Promise<Attendee>;

  /**
  * update address info of the loged in user
  * param address
  */
  // saveAddress(address: Address): Promise<Attendee>;

  /**
   * update personalInfo of the logged in user
   * param personalInfo
   */
  updatePersonalInfo(personalInfo: PersonalInfo): Promise<Attendee>;
  /**
   * grants consent to keep record about the user
   * param address
   */
  grantConsent(): Promise<Attendee>;

  /**
   * evaluations created by specified owner,
   * non-admins should not be allowed to read other ppls evals, key ~ Attendee.uuid
   * param authorEmail
   */
  getEvaluations(authorEmail: string): Promise<Map<string, LetterEvaluation>>;

  /**
   * admin only
   * todo not sute this one is needed
   */
  getAllEvaluations(): Promise<Map<string, LetterEvaluation[]>>;

  /**
   * add one more motivation letter for evaluation to the specified evaluator
   * current user must be evaluator
   * param email - evaluator's email
   */
  getNewEvaluation(email: string): Promise<[string, LetterEvaluation]>;

  /**
   * submit evaluations.
   * if the current user is not an admin, the LetetrEvaluations's author must be equal to the current user
   * if the current user is admin, his email should be set to "correctedBy"
   * param evals
   */
  submitEvaluations(evals: Map<string, LetterEvaluation>): Promise<void>;

  /**
   * delete evaluation, non-admin can delete only their own evaluation - hence email must equal current user's email
   * param email
   * param uuid
   */
  deleteEvaluation(email: string, uuid: string): Promise<void>;

  /**
   * retrieves motivation letter based on attendee's UUID
   * evaluator should only request letter's for which there is an evaluation with himas an owner
   * only evaluator or admin can use this
   * param uuid
   */
  getMotivationLetterByUuid(uuid: string): Promise<Letter>;

  getAllEvaluations(): Promise<Map<string, LetterEvaluation[]>>;

  getAllAttendees(): Promise<Array<Attendee>>;
  assignPositions(positions: Map<string, Position>): Promise<void> //email,Position

  /**
   * returns estimate of how mach demand there is for each role (only non-zero demand included)
   */
  getPositionDemand(): Promise<Map<string, number>>;

  updateAttendee(person: Partial<Attendee>, id?: string): Promise<Attendee>;

  /**
   * admin function to officially distribute positions
   */
  grantAttendeeStatus(): Promise<void>

  /**
   * 
   * @param idsAndTotals list of pairs (user id - total paid)
   */
  updatePayments(idsAndTotals: [string, number][])


  /**
   * obtain list of attendees that current user has right/responsibility to rate or view rating of
   */

  getAttendeesToRate(): Promise<Attendee[]>

  /**
   * adds or replaces the daily rating for an attendee for a particular day for the current user as the judge,
   * server MUST check that the user performing the action has permission to rate the rated attendee
   * @param attendeeId rated attendee's ID
   * @param day 0,1,2
   * @param rating - correct subtype - server SHOULD validate the type against the rated attendee role
   */
  setDailyRating(attendeeId: string, day: number, rating: Rating): Promise<Attendee>

  /**
   * adds or replaces the rating adjustment for an attendee. sets adjustment author to current user
   * server MUST check that the user performing the action has permission to rate the rated attendee
   * @param attendeeId rated attendee's ID
   * @param adjustment 
   */
  setRatingAdjustment(attendeeId: string, adjustment: number): Promise<Attendee>



}

export class CmakClientImpl implements CmakClient {
  apiName = "apicmakprofile";
  profilePath = "/profile";
  adminPath = "/admin";
  evalsPath = "/evals";
  ratingsPath = "/ratings"

  private handleError(apiError: any): never {

    if (apiError.response?.data) {
      console.warn("API CALL ERROR:", apiError.response?.data)
      const response = apiError.response?.data
      if (response?.reason) {
        throw { "reason": response.reason }
      } else {
        throw { "reason": "Chyba volání serveru" }
      }
    } else if (apiError instanceof Error) {
      console.warn("API CALL ERROR:", apiError)
      throw { "reason": apiError.message }
    } else {
      console.warn("API CALL ERROR:", apiError)
      throw { "reason": "Chyba volání serveru" }
    }
  }

  getMe(): Promise<Attendee> {
    const myInit = {
      headers: {},
      response: true,
    };

    return API.get(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  updateRegistration(registration: RegistrationInfo): Promise<Attendee> {
    const body: Partial<Attendee> = {
      registrationInfo: registration,
    };
    const myInit = {
      headers: {},
      response: true,
      body: body,
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  updateServices(services: Services) {
    const body: Partial<Attendee> = {
      services: services,
    };
    const myInit = {
      headers: {},
      response: true,
      body: body,
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }
  updatePersonalInfo(personalInfo: PersonalInfo): Promise<Attendee> {
    const body: Partial<Attendee> = {
      personalInfo: personalInfo,
    };
    const myInit = {
      headers: {},
      response: true,
      body: body,
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  grantConsent(): Promise<Attendee> {
    const body: Partial<Attendee> = {
      consent: true,
    };
    const myInit = {
      headers: {},
      response: true,
      body: body,
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  // saveAddress(address: Address): Promise<Attendee> {
  //   const body: Partial<Attendee> = {
  //     address: address,
  //   };
  //   const myInit = {
  //     headers: {},
  //     response: true,
  //     body: body,
  //   };

  //   return API.patch(this.apiName, this.profilePath, myInit)
  //     .then((response) => {
  //       return response.data as Attendee;
  //     })
  //     .catch(this.handleError);
  // }

  updateName(name: string): Promise<Attendee> {
    const body: Partial<Attendee> = {
      name: name,
    };
    const myInit = {
      headers: {},
      response: true,
      body: body,
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  getEvaluations(authorEmail: string): Promise<Map<string, LetterEvaluation>> {
    const myInit = {
      queryStringParameters: { email: authorEmail, mode: "author" },
      headers: {},
      response: true
    };

    return API.get(this.apiName, this.evalsPath, myInit)
      .then((response) => {
        return response.data as Map<string, LetterEvaluation>;
      })
      .catch(this.handleError);
  }

  getAllEvaluations(): Promise<Map<string, LetterEvaluation[]>> {
    const params = {
      mode: "evaluations"
    }
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: params
    };

    return API.get(this.apiName, this.adminPath, myInit)
      .then((response) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * add one more motivation letter for evaluation to the specified evaluator
   * current user must be evaluator
   * param email - evaluator's email
   */
  getNewEvaluation(email: string): Promise<[string, LetterEvaluation]> {
    const myInit = {
      queryStringParameters: { email: email },
      body: {},
      headers: {},
      response: true
    };

    return API.post(this.apiName, this.evalsPath, myInit)
      .then((response) => {
        return response.data as [string, LetterEvaluation];
      })
      .catch(this.handleError);
  }

  /**
   * submit evaluations.
   * if the current user is not an admin, the LetetrEvaluations's author must be equal to the current user
   * if the current user is admin, his email should be set to "correctedBy"
   * param evals
   */
  submitEvaluations(evals: Map<string, LetterEvaluation>): Promise<void> {
    const body: { [key: string]: LetterEvaluation } = {};
    evals.forEach((value, key) => body[key] = value)

    const myInit = {
      body: body,
      headers: {},
      response: true
    };

    return API.patch(this.apiName, this.evalsPath, myInit)
      .then((response) => {
        return
      })
      .catch(this.handleError);
  }

  /**
   * delete evaluation, non-admin can delete only their own evaluation - hence email must equal current user's email
   * param email
   * param uuid
   */
  deleteEvaluation(email: string, uuid: string): Promise<void> {
    throw new Error("not implemented yet")
  }

  /**
   * retrieves motivation letter based on attendee's UUID
   * evaluator should only request letter's for which there is an evaluation with himas an owner
   * only evaluator or admin can use this
   * param uuid
   */
  getMotivationLetterByUuid(uuid: string): Promise<Letter> {
    const myInit = {
      queryStringParameters: { uuid: uuid, mode: "letter" },
      headers: {},
      response: true
    };

    return API.get(this.apiName, this.evalsPath, myInit)
      .then((response) => {
        return response.data as Letter;
      })
      .catch(this.handleError);
  }

  getAllAttendees(): Promise<Array<Attendee>> {
    const params = {
      mode: "attendees"
    }
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: params
    };

    return API.get(this.apiName, this.adminPath, myInit)
      .then((response) => {
        return response.data as Attendee[];
      })
      .catch(this.handleError);
  }

  assignPositions(positions: Map<string, Position>): Promise<void> { //email,Position
    const body: { [key: string]: Position } = {};

    positions.forEach((value, key) => body[key] = value)

    const myInit = {
      headers: {},
      response: true,
      body: body
    };

    return API.patch(this.apiName, this.adminPath, myInit)
      .then((response) => {
        return;
      })
      .catch(this.handleError);
  }

  /**
   * returns estimate of how much demand there is for each role (only non-zero demand included)
   */
  getPositionDemand(): Promise<Map<string, number>> {
    const myInit = {
      queryStringParameters: { mode: "demand" },
      headers: {},
      response: true
    };

    return API.get(this.apiName, this.evalsPath, myInit)
      .then((response) => {
        return new Map(response.data as [string, number][]);
      })
      .catch(this.handleError);
  }
  updateAttendee(person: Partial<Attendee>, id?: string): Promise<Attendee> {
    const body: Partial<Attendee> = { ...person };
    const myInit = {
      headers: {},
      response: true,
      body: body,
      queryStringParameters: { id: id }
    };

    return API.patch(this.apiName, this.profilePath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  grantAttendeeStatus(): Promise<void> {
    const myInit = {
      headers: {},
      response: true,
      body: {},
      queryStringParameters: { mode: "grantAttendees" }
    };

    return API.post(this.apiName, this.adminPath, myInit)
      .then((response) => {
      })
      .catch(this.handleError);
  }

  updatePayments(idsAndTotals: [string, number][]) {
    const myInit = {
      headers: {},
      response: true,
      body: idsAndTotals,
      queryStringParameters: { mode: "updatePayments" }
    };

    return API.post(this.apiName, this.adminPath, myInit)
      .then((response) => {
      })
      .catch(this.handleError);
  }

  getAttendeesToRate(): Promise<Attendee[]> {
    const myInit = {
      headers: {},
      response: true,
    };

    return API.get(this.apiName, this.ratingsPath, myInit)
      .then((response) => {
        return response.data as Attendee[];
      })
      .catch(this.handleError);
  }

  setDailyRating(attendeeId: string, day: number, rating: Rating): Promise<Attendee> {
    const myInit = {
      headers: {},
      response: true,
      body: rating,
      queryStringParameters: { 
        id: attendeeId,
        day: day
       }
    };

    return API.post(this.apiName, this.ratingsPath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }

  setRatingAdjustment(attendeeId: string, adjustment: number): Promise<Attendee> {
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: { 
        id: attendeeId,
        adjustment: adjustment
      },
      body:{}
    };

    return API.post(this.apiName, this.ratingsPath, myInit)
      .then((response) => {
        return response.data as Attendee;
      })
      .catch(this.handleError);
  }
}


export class CmakServerCallEvent {
  constructor(public message: string, public error: boolean = false, public detail?: any) { }
}

export class EmittingCmakClient implements CmakClient {
  constructor(public delegate: CmakClient, public component: Vue) { }
  inProgress: Array<CmakServerCallEvent> = []

  close() {
    this.inProgress.forEach(e => this.component.$emit("cmak-server-call-ignore", e))
  }

  private emit<A>(call: () => Promise<A>, label?: string): Promise<A> {
    console.log(label)
    this.component.$emit("cmak-server-call-start", new CmakServerCallEvent(label ?? "Požadavek na server"))
    let promise: Promise<A>
    try {
      promise = call()
    } catch (e) {
      promise = new Promise<A>((resolve, reject) => { reject(e) })
    }
    const endEvent = new CmakServerCallEvent(label ?? "Požadavek na server")
    this.inProgress.push(endEvent)
    return promise.then(a => {
      this.inProgress.splice(this.inProgress.indexOf(endEvent), 1)
      this.component.$emit("cmak-server-call-end", endEvent)
      return a
    }).catch(reason => {
      this.inProgress.splice(this.inProgress.indexOf(endEvent), 1)
      console.log("failing:" + label, reason)
      this.component.$emit("cmak-server-call-end", new CmakServerCallEvent(reason["reason"] ?? `Chyba serveru (${label})`, true))
      throw reason
    })

  }

  getMe(): Promise<Attendee> {
    return this.emit(() => this.delegate.getMe(), "Načítání profilu")
  }

  updateRegistration(registration: RegistrationInfo): Promise<Attendee> {
    return this.emit(() => this.delegate.updateRegistration(registration), "Ukládání registrace")
  }

  updateServices(services: Services): Promise<Attendee> {
    return this.emit(() => this.delegate.updateServices(services), "Ukládání nastavení služeb")
  }

  updateName(name: string): Promise<Attendee> {
    return this.emit(() => this.delegate.updateName(name), "Ukládání jména")
  }

  // saveAddress(address: Address): Promise<Attendee> {
  //   return this.emit(()=>this.delegate.saveAddress(address),"Ukládání adresy")
  // }

  updatePersonalInfo(personalInfo: PersonalInfo): Promise<Attendee> {
    return this.emit(() => this.delegate.updatePersonalInfo(personalInfo), "Ukládání osobních údajů")
  }

  grantConsent(): Promise<Attendee> {
    return this.emit(() => this.delegate.grantConsent(), "Udělení souhlasu")
  }
  getEvaluations(authorEmail: string): Promise<Map<string, LetterEvaluation>> {
    return this.emit(() => this.delegate.getEvaluations(authorEmail), "Načtení existujících hodnocení")
  }
  getAllEvaluations() {
    return this.emit(() => this.delegate.getAllEvaluations(), "Načtení všech existujících hodnocení")
  }
  getNewEvaluation(email: string): Promise<[string, LetterEvaluation]> {
    return this.emit(() => this.delegate.getNewEvaluation(email), "Načtení dalšího hodnocení")
  }
  submitEvaluations(evals: Map<string, LetterEvaluation>): Promise<void> {
    return this.emit(() => this.delegate.submitEvaluations(evals), "Uložení hodnocení")
  }
  deleteEvaluation(email: string, uuid: string): Promise<void> {
    return this.emit(() => this.delegate.deleteEvaluation(email, uuid), "Smazání hodnocení")
  }
  getMotivationLetterByUuid(uuid: string): Promise<Letter> {
    return this.emit(() => this.delegate.getMotivationLetterByUuid(uuid), "Načtení dopisu")
  }
  getAllAttendees(): Promise<Attendee[]> {
    return this.emit(() => this.delegate.getAllAttendees(), "Načtení všech uživatelů")
  }
  assignPositions(positions: Map<string, Position>): Promise<void> {
    return this.emit(() => this.delegate.assignPositions(positions), "Uložení rolí")
  }
  getPositionDemand(): Promise<Map<string, number>> {
    return this.emit(() => this.delegate.getPositionDemand(), "Načtení poptávky po rolích")
  }
  updateAttendee(person: Partial<Attendee>, id: string): Promise<Attendee> {
    return this.emit(() => this.delegate.updateAttendee(person, id), "Uložení změn do záznamu účastníka")
  }
  grantAttendeeStatus(): Promise<void> {
    return this.emit(() => this.delegate.grantAttendeeStatus(), "Potvrzení nastavených rolí")
  }
  updatePayments(idsAndTotals: [string, number][]): Promise<void> {
    return this.emit(() => this.delegate.updatePayments(idsAndTotals), "Aktualizace plateb")
  }

  getAttendeesToRate(): Promise<Attendee[]> {
    return this.emit(() => this.delegate.getAttendeesToRate(), "Načtení seznamu hodnocených")
  }

  setDailyRating(attendeeId: string, day: number, rating: Rating): Promise<Attendee> {
    return this.emit(() => this.delegate.setDailyRating(attendeeId, day, rating), "Ohodnocení účastníka")
  }

  setRatingAdjustment(attendeeId: string, adjustment: number): Promise<Attendee> {
    return this.emit(() => this.delegate.setRatingAdjustment(attendeeId, adjustment), "Úprava ratingu")
  }
}

export const Client = new CmakClientImpl();

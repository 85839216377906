






































































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import {
  Person,
  GameRating,
  DailyRating,
  PositionFilter,
  ApplicableFilter,
  BodyFilter,
  BodyType,
  Attendee,
  Rating,
  getRatingType,
  Position,
  calculateSingleRatingOverall,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { calculateOverall, theClient } from "../cmak-ui";

@Component
export default class adjustmentRating extends Vue {
  @Prop({ required: true }) person!: Attendee;
  @Prop({ required: false }) saveInProgress!: boolean;
  adjustment: number | null = null;
  overall: number | null = null;

  mounted() {
    this.adjustment = this.person.gameRating?.adjustment ?? null
    this.calculateLocalOverall();
  }

  getRatingType = getRatingType;

  calculateOverall = calculateOverall;

  @Emit()
  setAdjustment(adjustment: number) {}

  @Watch("person")
  calculateLocalOverall() {
    this.overall = calculateOverall(this.person, this.adjustment??undefined);
  }
}

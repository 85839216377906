













































































import Vue from "vue";
import { Prop, Emit, Component } from "vue-property-decorator";
import {
  Position,
  PartisanPosition,
  PositionFilter,
  BodyType,
  SenatePosition,
  CongressPosition,
  MinisterPosition,
  LobbyistPosition,
  Committee,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { theCmak, distinctPositions, theClient } from "../cmak-ui";
import {EmittingCmakClient} from "../cmak-client";
@Component
export default class RoleSelection extends Vue {
  theClient=new EmittingCmakClient(theClient,this)
  closeOnClick = true;
  private cmak = theCmak;
  @Prop({ required: false })
  initialRole?: Position;
  @Prop({ required: false })
  demand!: Map<string, number>;
  @Prop({ required: false })
  usedRoles?: Position[];
  
  demandCache
  party = "";
  roleType = "";
  committee = "";
  congressCommittees = this.cmak.congressCommittees;
  senateCommittees = this.cmak.senateCommittees;
  departments = this.cmak.departments;
  finalPosition = "";

  // @Prop({required:false}) position?:Position

  mounted() {
    if (this.initialRole) {
      this.party = (this.initialRole as PartisanPosition)?.party;
      this.roleType = this.initialRole?.body;
      this.committee = (this.initialRole as
        | CongressPosition
        | SenatePosition)?.committee;
      this.finalPosition = this.initialRole?.identifier;
    }
    this.demandCache=this.demand
    if(!this.demandCache){
      this.theClient.getPositionDemand().then((demand) => {
      this.demandCache = demand     
      });
    }
    
  }

  committeesForSelection():Committee[] {
    if (this.roleType === BodyType.Senate) {
      if (this.usedRoles){
        return Array.from(new Set(this.remainingRoles().filter(p=>p.body==BodyType.Senate).map(r=>(r as SenatePosition).committee).filter(c=>c))).map(ci=>theCmak.committeesById.get(ci)!)
      }else{
        return this.cmak.senateCommittees;
      }
    } else {
      if (this.usedRoles){
        return Array.from(new Set(this.remainingRoles().filter(p=>p.body==BodyType.Congress).map(r=>(r as CongressPosition).committee).filter(c=>c))).map(ci=>theCmak.committeesById.get(ci)!)
      }else{
        return this.cmak.congressCommittees;
      }
    }
  }

  cleanUp() {
    this.committee = "";
    this.finalPosition = "";
  }

  demandOf(position: Position): number {
    console.log("demand of ", position);
    return Math.round(this.demand?.get(position.identifier)??0);
  }
  positionById(identifier: string): Position[] {
    return this.cmak.positions.filter((p) => p.identifier == identifier);
  }

        typeFilters = [
          { value: 'Congress', label: 'Sněmovna' },
          { value: 'Senate', label: 'Senát' },
          { value: 'Government', label: 'Vláda' },
          { value: 'Lobby', label: 'Lobby' },
          { value: 'TCK', label: 'TCK' },
        ]
  availableTypes(): { value: string, label: string }[]{
    let result: { value: string, label: string }[]
    if (this.usedRoles){
      result =  Array.from(new Set(this.remainingRoles().map(r=>r.body)).keys()).map(b=>{return {value:b.toString(), label: this.typeFilters.find(tf=>tf.value==b)?.label! }})
    }else{
      result = this.typeFilters;
    }
    return result;
  }

  partyFilters=[
          { value: 'Republican', label: 'Republikáni' },
          { value: 'Democrat', label: 'Demokrati' },
        ]

  availableParties(): { value: string, label: string }[]{
    let result: { value: string, label: string }[]
    if (this.usedRoles){
      console.log("usedRoles")
      result =  Array.from(new Set(this.remainingRoles()!.filter(r=>(r as PartisanPosition).party).map(r=>(r as PartisanPosition).party)).keys()).map(p=>{return {value:p.toString(), label: this.partyFilters.find(pf=>pf.value==p)?.label! }})
    }else{
      result =  this.partyFilters;
    }    
    return result;
  }
  remainingRoles(){
    let positions = [...this.cmak.positions]
    if(this.usedRoles){
      this.usedRoles.forEach(ur=>{
        const index = positions.findIndex(p=>ur.identifier==p.identifier)
        if(index>=0){
          positions.splice(index,1)
        }
      })
    }
    return distinctPositions(positions);
  }
  availablePositions() {
    let positions = this.remainingRoles()
    const result = positions
      .filter((position) => {
        if (position.body === this.roleType) {
          if (
            this.roleType === BodyType.Senate ||
            this.roleType === BodyType.Congress
          ) {
            const partisanPosition = position as PartisanPosition;
            if (partisanPosition.party === this.party) {
              const committeePosition = partisanPosition as
                | SenatePosition
                | CongressPosition;
              if (committeePosition.committee === this.committee) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        }

        return false;
      })
      .map((position) => {
        switch (position.body) {
          case "Senate":
            return {
              text:`${(position as SenatePosition).fraction.name}, ${
                (position as SenatePosition).state
              }`,
              identifier: position.identifier,
            };
          case "Congress":
            return {
              text: `${(position as CongressPosition).fraction.name}, ${
                (position as CongressPosition).state
              }`,
              identifier: position.identifier,
            };
          case "Government":
            return {
              text: (position as MinisterPosition).department.name,
              identifier: position.identifier,
            };
          case "Lobby":
            return {
              text: (position as LobbyistPosition).organization.name,
              identifier: position.identifier,
            };
          case "TCK":
            return {
              text: "Novinář",
              identifier: position.identifier,
            };
        }
      });
    return result;
  }

  positionSelected() {
    this.setPosition(
      this.cmak.positions.filter((p) => p.identifier === this.finalPosition)[0]
    );
    return true;
  }

  @Emit()
  setPosition(position: Position) {
    // console.log(position);
  }
  // @Prop({ required: true }) availableRoles!: AttendeePosition[]
  // @Prop({ required: true }) primaryRoles!: AttendeePosition[]
  // @Prop({ required: true }) secondaryRoleFilters!: PositionFilter[]
}

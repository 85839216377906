





































































































































































import {
  Attendee,
  Position,
  Person,
  Authorization,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { getCurrentRatingDay } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-2022";
import { theClient, theStore, theConfig } from "../cmak-ui";
import { CmakServerCallEvent, EmittingCmakClient } from "../cmak-client";
import { Vue, Component, Watch } from "vue-property-decorator";
import PersonHeader from "../components/PersonHeader.vue";
import Home from "./views/Home.vue";
import {Auth} from '@aws-amplify/auth'

@Component({ components: { PersonHeader } })
export default class Main extends Vue {
  theClient=new EmittingCmakClient(theClient,this)
  menuVisible: boolean | null = null;
  snackbar = false
  error:string|null=null
  inProgress = 0
  registrationsAllowed: boolean | null = null;
  hideAssignedRoles: boolean | null = null;

  created() {
    theConfig.get().then(c => {this.registrationsAllowed = c.registrationsAllowed; this.hideAssignedRoles = c.hideAssignedRoles})
  }

  person() {
    return theStore.person;
  }

  @Watch("navList")
  onPropertyChanged(value: string, oldValue: string) {
    this.menuVisible = false;
  }

  hideMenu() {
    this.menuVisible = false;
  }

  isAdmin(): boolean {
    return this.person()?.authorizations?.find(a => a == Authorization.ADMIN || a == Authorization.EVALUATOR)?true:false
  }

  canViewService() {
    return this.person()?.authorizations?.includes(Authorization.ATTENDEE) || this.person()?.authorizations?.includes(Authorization.ORGANIZER);
  }

  isOrganizer(): boolean {
    return this.person()?.authorizations?.includes(Authorization.ORGANIZER)?true:false;
  }
  //so far we let in any organizer, but the ones who have no responsibilities should not be let in

  callStart(event:CmakServerCallEvent){
    this.inProgress++
    console.log(event)
  }

  callEnd(event:CmakServerCallEvent){
    this.inProgress--
    if(event.error){
      this.snackbar = true
      this.error=event.message
    };
    
    console.log(event)
  }

  getCurrentRatingDay() {
    return getCurrentRatingDay()
  }

  @Watch('$route', { immediate: true, deep: true })
   onUrlChange(newVal: any) {
      this.inProgress = 0
  }

  mounted() {
    this.theClient.getMe().then((a) => {
      theStore.person = a;
    }); //todo - possibly redundant
  }

  signOut() {
    Auth.signOut()
      .then(data => this.$router.push({path:'/'}))
      .catch(err => console.log(err));
  }
}







import Vue from "vue";
import PersonHeader from "./PersonHeader.vue";
import { Prop, Component } from "vue-property-decorator";
import {
  Attendee,
  Authorization,
  BodyType,
  CongressCommitteeId,
  CongressPosition,
  LobbyistPosition,
  MinisterPosition,
  PartisanPosition,
  SenateCommitteeId,
  SenatePosition
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import {saveAs} from "file-saver"
import {exportCsv} from "../cmak-csv"
import {RatedAttendee} from "../cmak-ui"
@Component({ components: { PersonHeader } })
export default class AttendeeProfile extends Vue {
    @Prop({required:true})
    rAttendees?:RatedAttendee[]

    save(){
        var blob = new Blob([exportCsv(this.rAttendees!,[
            ["Jméno", a=>a?.attendee.name],
            ["Strana", a=>a?.attendee.position? (a?.position as PartisanPosition)?.party:undefined],
            ["Frakce", a=>a?.attendee.position? (a?.position as (SenatePosition|CongressPosition))?.fraction?.name:undefined],
            ["Stát", a=>a?.attendee.position? (a?.position as (SenatePosition|CongressPosition))?.state:undefined],
            ["Výbor", a=>a?.attendee.position? (a?.position as (SenatePosition|CongressPosition))?.committee:undefined],
            ["Ministr", a=>a?.attendee.position? (a?.position as MinisterPosition)?.department?.name:undefined],
            ["Lobby společnost", a=>a?.attendee.position? (a.position as LobbyistPosition)?.organization?.name:undefined],
            ["Novinář", a=>a?.attendee.position?.body == BodyType.TCK ? "TCK":undefined],
            ["Celkové hodnocení", a=>a?.overallRating? Math.round(a.overallRating):undefined],
            ["Připravenost", a=>a?.readiness? Math.round(a.readiness):undefined],
            ["Lobbing", a=>a?.lobbing? Math.round(a.lobbing):undefined],
            ["Mediální obraz", a=>a?.mediaImage? Math.round(a.mediaImage):undefined],
            ["Aktivita", a=>a?.activity? Math.round(a.activity):undefined],
            ["Autentičnost", a=>a?.authenticity? Math.round(a.authenticity):undefined],
            ["Týmová práce", a=>a?.teamwork? Math.round(a.teamwork):undefined],
            ["Kreativita", a=>a?.creativity? Math.round(a.creativity):undefined],
            ["Iniciativa", a=>a?.initiative? Math.round(a.initiative):undefined],
            ["Integrita", a=>a?.integrity? Math.round(a.integrity):undefined],
            ["Úprava hodnocení", a=>a?.adjustment]
        ])])
        saveAs(blob, 'export.csv')
    }
}























import { Vue, Component, Prop } from "vue-property-decorator";

import { LetterEvaluation } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";

@Component
export default class Evaluation extends Vue {
  @Prop() eval!: LetterEvaluation | null;

  color(r: number): string {
    if (r > 75) {
      return "green";
    } else if (r > 25) {
      return "orange";
    } else if (r > 0) {
      return "red";
    } else {
      return "";
    }
  }
}












import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import { Auth } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types/Auth";
import {
  User,
  Authorization,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";

import { theStore, theClient } from "../cmak-ui";
import { FakeCmakClient } from "@/cmak-fake-client";

@Component
class AuthButton extends Vue {
  inProgress = false;
  //---------------------
  login(): void {
    this.inProgress = true;
    if ((theClient as FakeCmakClient).fake) {
      this.checkUser();
    } else {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      }).then((creds) => this.checkUser());
    }
  }

  logout(): void {
    Auth.signOut().then((r) => r);
  }
  //---------------------
  authenticated = false;
  currentUser: User | undefined;

  //---------------------
  created() {
    this.inProgress = true;
    if ((theClient as FakeCmakClient).fake) {
     
        setTimeout(() =>{this.inProgress=false},2000);     
    }else{
      this.checkUser()
    }
  }

  //---------------------
  private checkUser() {
    if ((theClient as FakeCmakClient).fake) {
      const fake = theClient as FakeCmakClient;
      theStore.person = fake.currentUser = fake.admins[0];
      fake.currentUser.authorizations!.push(Authorization.EVALUATOR);
      this.userLoggedIn(fake.currentUser);
    } else {
      Auth.currentUserInfo()
        .then((userInfo) => {
          console.log(userInfo);
          if (userInfo.attributes) {
            this.currentUser = {
              email: userInfo.attributes.email,
              name: userInfo.attributes.name,
              pic: userInfo.attributes.picture,
            };
            let newState = this.currentUser ? true : false;

            if (!this.authenticated && newState) {
              theClient.getMe().then((a) => {
                theStore.person = a;
                this.userLoggedIn(a);
              });
            }else{
              this.inProgress = false;
            }
            this.authenticated = newState;
          }
        })
        .catch((e) => {
          this.currentUser = undefined;
          this.authenticated = false;
          this.inProgress = false;
        });
      this.displayUserInf();
    }
  }

  private displayUserInf() {
    console.log(this.currentUser);
  }

  @Emit()
  userLoggedIn(user: User) {}
}

export default AuthButton;

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Attendee } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { theClient, theStore, theConfig, isReadyForPosition } from "../cmak-ui";
import { EmittingCmakClient } from "../cmak-client";

@Component
export default class ConsentDialog extends Vue {
  @Prop({ required: true }) person!: Attendee;
  consent: boolean = false;
  consent1: boolean = false;
  consent2: boolean = false;
  theClient = new EmittingCmakClient(theClient, this);
  dialog = true;

  saveConsent() {
    this.theClient.grantConsent().then((a) => {
      this.consent = a.consent!;
      this.person = a;
      theStore.person = a;
      this.dialog = false;
    });
  }
}

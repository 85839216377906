import {
  Person,
  Attendee,
  RegistrationInfo,
  Position,
  BodyType,
  LobbyType,
  LobbyistPosition,
  MinisterPosition,
  CongressPosition,
  SenatePosition,
  PositionFilter,
  LetterEvaluation,
  Letter,
  ApplicableFilter,
  getRatingType,
  calculateSingleRatingOverall,
  FinanceInfo,
  GameRating,
  Rating
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";

import { CmakDefinition } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-2022";
import { FakeCmakClient } from "./cmak-fake-client";
import {
  CmakConfig,
  CmakConfigImpl,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-config";
import { Cached } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-cache";

import Vue from "vue";
import { CmakClient, CmakClientImpl } from "./cmak-client";

export const theCmak = new CmakDefinition();

export function shortPositionText(positionToDisplay: Position): string {
  switch (positionToDisplay.body) {
    case BodyType.Senate: {
      let party: string;
      const position = positionToDisplay as SenatePosition;
      if (position.party === "Republican") {
        party = "REP";
      } else {
        party = "DEM";
      }
      return `Senátor za ${party}/${position.fraction?.name}, ${position.state} ve ${position.committee}`
    }
    case BodyType.Congress: {
      const position = positionToDisplay as CongressPosition;
      let party: string;
      if (position.party === "Republican") {
        party = "REP";
      } else {
        party = "DEM";
      }
      return `Poslanec za ${party}/${position.fraction?.name}, ${position.state} ve ${position.committee} `;
    }
    case BodyType.Government: {
      const position = positionToDisplay as MinisterPosition;
      return "Ministr: " + position.department.name;
    }

    case BodyType.Lobby: {
      const position = positionToDisplay as LobbyistPosition;
      return `Lobista za ${position.organization.name}`;
    }

    case BodyType.TCK: {
      return "Novinář ";
    }
    default:
      return "Neznámá pozice";
  }
}

export function filterToText(filter: PositionFilter): string {
  switch (filter.type) {
    case "body": {
      switch (filter.value) {
        case BodyType.Senate: {
          return "Senát";
        }
        case BodyType.Congress: {
          return "Sněmovna";
        }
        case BodyType.Government: {
          return "Vláda";
        }
        case BodyType.Other: {
          return "Ostatní";
        }
        default: {
          return filter.value;
        }
      }
    }
    case "committee": {
      return theCmak.committeesById.get(filter.value)?.name ?? filter.value;
      // pořešit mobile problem (nevejde se tam celé jméno, potřebujeme jen část za "Výbor pro " => Národní bezpečnost, Ozbrojené složky atd.)
    }
    case "fraction": {
      return theCmak.fractionsById.get(filter.value)?.name ?? filter.value;
    }
    case "party": {
      if (filter.value === "Republican") {
        return "Republikáni";
      } else {
        return "Demokraté";
      }
    }
    case "lobbyType": {
      switch (filter.value) {
        case LobbyType.Corp: {
          return "Korporace";
        }
        case LobbyType.Np: {
          return "Neziskovka";
        }
        case LobbyType.Un: {
          return "Odbory";
        }
      }
    }

    default: {
      return filter.value;
    }
  }
}

export function countWords(delta: any): number {
  if (delta&&delta["ops"]){
  const text = delta.ops
    .map((d) => d["insert"] as string)
    .reduce((a, b) => a + b, "")
    .trim();

  return text.length == 0 ? 0 : text.split(/\s+/).length;
  }else{
    return 0
  }
}
export function averageEval(evals: LetterEvaluation[]): LetterEvaluation {
  const validEvals = evals.filter(
    (e) => e.creativity + e.grammar + e.motivation + e.karasIndex > 0
  );
  const averageEval: LetterEvaluation = validEvals.reduce(
    (e1, e2) => {
      return {
        creativity: e1.creativity + e2.creativity,
        grammar: e1.grammar + e2.grammar,
        motivation: e1.motivation + e2.motivation,
        karasIndex: e1.karasIndex + e2.karasIndex,
      };
    },
    {
      creativity: 0,
      grammar: 0,
      karasIndex: 0,
      motivation: 0,
    }
  );
  if (validEvals.length > 0) {
    averageEval.creativity /= validEvals.length;
    averageEval.grammar /= validEvals.length;
    averageEval.motivation /= validEvals.length;
    averageEval.karasIndex /= validEvals.length;
  }
  return averageEval;
}
export function satisfactionEstimate(person: Attendee, role: Position): number {
  if (!person.registrationInfo?.primaryRoles) {
    return 0;
  }
  if (role.identifier == person.registrationInfo.primaryRoles[0]?.identifier) {
    return 100;
  } else if (
    role.identifier == person.registrationInfo.primaryRoles[1]?.identifier
  ) {
    return 95;
  } else if (
    role.identifier == person.registrationInfo.primaryRoles[2]?.identifier
  ) {
    return 90;
  } else {
    const filters = ApplicableFilter.makeApplicable(
      person.registrationInfo.secondaryRoleFilters ?? []
    );
    let sum: number = 0;
    const filterValue =
      90 / ((filters.length * filters.length + filters.length) / 2);
    filters.forEach((f, i) => {
      if (f.matches(role)) {
        sum = sum + filterValue * (filters.length - i);
      }
    });
    return sum;
  }
}

function equalPositions(a?: Position, b?: Position): boolean {
  return a?.identifier == b?.identifier;
}

function equalPosition(a?: Position): (p: Position) => boolean {
  return (b: Position) => a?.identifier == b?.identifier;
}

export function distinctPositions(positions: Position[]): Position[] {
  return Array.from(new Map(positions.map((p) => [p.identifier, p])).values());
}

export function isAvailable(role: Position, roles: Position[]): boolean {
  return roles.filter(equalPosition(role)).length > 0;
}

export function isReadyForPosition(a: Attendee): boolean {
  if (!a.registrationInfo) {
    return false;
  } else if (!a.registrationInfo.primaryRoles) {
    return false;
  } else if (a.registrationInfo.primaryRoles.length == 0) {
    return false;
  } else if (!((a.registrationInfo.motivationalLetter) && (countWords(a.registrationInfo?.motivationalLetter?.delta??[])>=5))) {
    return false;
  } else {
    return true;
  }
  // return (attendee.registrationInfo?true:false)
  // && (attendee.registrationInfo?.primaryRoles?true:false)
}

export function computeBalance(financeInfo?: FinanceInfo):number {
    return -(financeInfo?.fees?.reduce((total, fee) => (total = total + fee.price), 0)??0) + (financeInfo?.paid??0) + (financeInfo?.balanceCorrection??0)
}


export function distributePositions(
  positions: Position[],
  attendees: { attendee: Attendee; position?: Position }[],
  demand: Map<string, number>
) {
  console.log("distributing:", positions)
  console.log("distributing to:", attendees)
  let remainingPositions = [...positions];  
  attendees.forEach((a) => {
    a.position = undefined
    if (isReadyForPosition(a.attendee) && remainingPositions.length != 0) {
      for (const p of a.attendee.registrationInfo?.primaryRoles ?? []) {
        if (isAvailable(p, remainingPositions)) {
          a.position = p;
          const pos = remainingPositions.findIndex((r) =>
            equalPositions(r, a.position)
          );
          remainingPositions.splice(pos, 1);
          break;
        }
      }
      if (!a.position) {
        let usablePositions = remainingPositions;
        for (const f of a.attendee.registrationInfo?.secondaryRoleFilters ??
          []) {
          const filter = ApplicableFilter.makeApplicable([f])[0];
          const nextFiltered = usablePositions.filter((r) => filter.matches(r));
          if (nextFiltered.length > 0) {
            usablePositions = nextFiltered;
          }
        }
        //least demanded
        a.position = usablePositions.sort(
          (x, y) =>
            (demand.get(x.identifier) ?? 0) - (demand.get(y.identifier) ?? 0)
        )[0];
        remainingPositions = remainingPositions.filter(
          (r) => !equalPositions(r, a.position)
        );
      }
    }
  });
}

export function averageRatingInCategories(gameRating: GameRating, day?: number): Rating {
  const ratings: Array<Rating> = (
    day == undefined
      ? gameRating.dailyRatings.flat()
      : gameRating.dailyRatings[day]
  ).map((r) => r.rating);
  
  return ratings.reduce(
    (avg, curr, index, ratings) => {
      Object.entries(curr)
        .filter((entry) => typeof entry[1] === "number")
        .forEach((entry) => avg[entry[0]] = (avg[entry[0]] ?? 0) + entry[1] / ratings.length
        );
      return avg;
    },
    { type: ratings[0].type } as Rating
  );
}

export function calculateOverall(person: Attendee, localAdjustment?: number): number {
  let result: number = (
    person.gameRating?.dailyRatings
      ?.flat()
      .map((r) => r?.rating)
      .reduce(
        (avg, curr, index, ratings) =>
          avg + (curr?calculateSingleRatingOverall(person, curr):0) / ratings.length,
        0
      ) ?? 0
  )
  
  const adjustment: number | undefined = localAdjustment??person.gameRating?.adjustment

  if (adjustment || adjustment == 0) {
    if (getRatingType(person.position!) === "Congress") {
      result = result*0.8 + (adjustment*0.2)
    }else if (getRatingType(person.position!) === "Government") {
      result = result*(2/3) + (adjustment*(1/3))
    }else if (getRatingType(person.position!) === "Lobby") {
      result += adjustment
    }
  }
  return (result<100)?result:100
}

class UIConfig {
  cache = new Cached<CmakConfig>(this.computeConfig);
  cfgUrl?: string;
  private computeConfig(): Promise<CmakConfig> {
    if (!this.cfgUrl){
      this.cfgUrl = localStorage.getItem("cmak-cfg-url")??undefined
    }
    return fetch(this.cfgUrl!)
      .then((response) => {
        return response.json() as Promise<Partial<CmakConfig>>;
      })
      .then((patch) => new CmakConfigImpl(patch))
      .catch((err) => {
        console.warn("failed to load config patch", err);
        return new CmakConfigImpl();
      });
  }
  initialize(region: string, bucketName: string) {
    this.cfgUrl = `https://${bucketName}.s3-${region}.amazonaws.com/config/config.json`;
    localStorage.setItem("cmak-cfg-url",this.cfgUrl)
    console.log(`set cfg URL to ${this.cfgUrl}`);
    this.cache.get();
  }
  get(): Promise<CmakConfig> {
    return this.cache.get();
  }
  constructor(){
    this.cfgUrl=localStorage.getItem("cmak-cfg-url")??undefined
  }
}

export class RatedAttendee {
  rank: number = 0;
  position?: Position;
  overallRating?: number;
  readiness?: number;
  lobbing?: number;
  mediaImage?: number;
  activity?: number;
  authenticity?: number;
  teamwork?: number;
  creativity?: number;
  initiative?: number;
  integrity?: number;
  adjustment?: number;

  constructor(public attendee: Attendee) {
    this.overallRating = calculateOverall(attendee);
    this.position = attendee.position!;
  }
}

// export const theClient:CmakClient = new FakeCmakClient()
export const theClient:CmakClient = new CmakClientImpl()
export const theStore = Vue.observable({
  person: null as Attendee | null,
});

export const theConfig = new UIConfig();

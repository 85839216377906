





































































import { Vue, Component } from "vue-property-decorator";
import AuthButton from "../components/AuthButton.vue";

@Component({ components: { AuthButton } })
export default class Welcome extends Vue {}

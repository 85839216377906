"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var AddressEditor_vue_1 = require("@/components/AddressEditor.vue");
var HelloWorld_vue_1 = require("@/components/HelloWorld.vue");
var AttendeeProfile_vue_1 = require("@/components/AttendeeProfile.vue");
var cmak_ui_1 = require("../cmak-ui");
var cmak_client_1 = require("../cmak-client");
var Registration = /** @class */ (function (_super) {
    __extends(Registration, _super);
    function Registration() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.theClient = new cmak_client_1.EmittingCmakClient(cmak_ui_1.theClient, _this);
        _this.store = cmak_ui_1.theStore;
        _this.consent = false;
        _this.consent1 = false;
        _this.consent2 = false;
        _this.attendee = null;
        _this.basicsValid = false;
        _this.rules = {
            name: [
                function (val) {
                    return ((val !== null && val !== void 0 ? val : "").split(" ").length > 1 &&
                        (val || "").split(" ").length <= 3 &&
                        (val === null || val === void 0 ? void 0 : val.length) > 5) ||
                        "Neplatné jméno";
                },
            ],
            schoolName: [
                function (val) {
                    return ((val !== null && val !== void 0 ? val : "").split(" ").length >= 1) ||
                        "Neplatné jméno";
                },
            ],
            email: [
                function (email) { return (email === null || email === void 0 ? void 0 : email.match(/^[a-zA-Z\.\-_0-9]+@[a-zA-Z\.\-0-9]+\.[a-zA-Z]{2,}$/g)) ? true : false; }
            ]
        };
        _this.basicInfoSaved = false;
        _this.registrationsAllowed = true;
        _this.nameSaved = false;
        _this.schoolName = null;
        _this.schoolAddress = null;
        _this.schoolSaved = true;
        _this.schoolValid = false;
        _this.schoolAddressValid = false;
        return _this;
    }
    Registration.prototype.created = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.name = (_a = cmak_ui_1.theStore.person) === null || _a === void 0 ? void 0 : _a.name;
        this.attendee = cmak_ui_1.theStore.person;
        this.primaryEmail = (_d = (_c = (_b = cmak_ui_1.theStore.person) === null || _b === void 0 ? void 0 : _b.registrationInfo) === null || _c === void 0 ? void 0 : _c.primaryEmail) !== null && _d !== void 0 ? _d : (_e = cmak_ui_1.theStore.person) === null || _e === void 0 ? void 0 : _e.email;
        this.consent = ((_f = cmak_ui_1.theStore.person) === null || _f === void 0 ? void 0 : _f.consent) ? true : false;
        this.schoolAddress = (_j = (_h = (_g = cmak_ui_1.theStore.person) === null || _g === void 0 ? void 0 : _g.school) === null || _h === void 0 ? void 0 : _h.address) !== null && _j !== void 0 ? _j : null;
        this.basicsValid = true;
        this.schoolValid = this.schoolName ? true : false;
        this.basicInfoSaved = true;
        this.schoolAddressValid = this.schoolAddress ? true : false;
        this.schoolName = (_m = (_l = (_k = cmak_ui_1.theStore.person) === null || _k === void 0 ? void 0 : _k.school) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : null;
        cmak_ui_1.theConfig
            .get()
            .then(function (c) { return (_this.registrationsAllowed = c.registrationsAllowed); });
    };
    Registration.prototype.saveConsent = function () {
        var _this = this;
        this.theClient.grantConsent().then(function (a) {
            _this.consent = a.consent;
            _this.attendee = a;
            cmak_ui_1.theStore.person = a;
        });
    };
    Registration.prototype.saveBasicInfo = function () {
        var _this = this;
        var _a, _b, _c;
        var registrationInfo = (_b = (_a = cmak_ui_1.theStore.person) === null || _a === void 0 ? void 0 : _a.registrationInfo) !== null && _b !== void 0 ? _b : {};
        registrationInfo.primaryEmail = this.primaryEmail;
        this.theClient.updateAttendee({ name: this.name, registrationInfo: registrationInfo }, (_c = cmak_ui_1.theStore.person) === null || _c === void 0 ? void 0 : _c.id).then(function (a) {
            _this.attendee = a;
            cmak_ui_1.theStore.person = a;
            _this.basicInfoSaved = true;
        });
    };
    Registration.prototype.isReadyForPosition = function (attendee) {
        return cmak_ui_1.isReadyForPosition(attendee);
    };
    Registration.prototype.saveAddress = function (address) {
        this.schoolAddress = address;
        this.schoolSaved = false;
    };
    Registration.prototype.saveSchool = function () {
        var _this = this;
        var _a;
        this.theClient.updateAttendee({ school: { address: this.schoolAddress, name: this.schoolName } }, (_a = cmak_ui_1.theStore.person) === null || _a === void 0 ? void 0 : _a.id).then(function (a) {
            _this.attendee = a;
            cmak_ui_1.theStore.person = a;
            _this.schoolSaved = true;
        });
    };
    Registration = __decorate([
        vue_property_decorator_1.Component({
            components: {
                AddressEditor: AddressEditor_vue_1.default,
                HelloWorld: HelloWorld_vue_1.default,
                AttendeeProfile: AttendeeProfile_vue_1.default,
            },
        })
    ], Registration);
    return Registration;
}(vue_property_decorator_1.Vue));
exports.default = Registration;









































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import {
  Person,
  GameRating,
  DailyRating,
  PositionFilter,
  ApplicableFilter,
  BodyFilter,
  BodyType,
  Attendee,
  Rating,
  getRatingType,
  Position,
  calculateSingleRatingOverall
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import PersonHeader from "./PersonHeader.vue";
import { calculateOverall, averageRatingInCategories } from "../cmak-ui"

@Component({ components: { PersonHeader } })
export default class RatingOverview extends Vue {
  @Prop({ required: true }) person!: Attendee;

  averageCategoryRating: Rating | null = null;

  created() {
    console.log(this.person);
  }

  mounted() {
    console.log(this.person);
    this.recalculateRating()
  }

  averageRatingInCategories = averageRatingInCategories;

  getRatingType = getRatingType;

  calculateOverall = calculateOverall

  @Watch("person")
  recalculateRating() {
    
    console.log(this.person)
    if (
      this.person &&
      this.person.gameRating &&
      this.person.gameRating.dailyRatings
    ) {
      this.averageCategoryRating = this.averageRatingInCategories(
        this.person.gameRating
      );
      console.log("avg")
      console.log(this.averageCategoryRating)
    }
  }

  // mounted() {
  //   this.recalculateRating()
  // }
}














































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Person,
  GameRating,
  DailyRating,
  Rating,
  Attendee,
  getRatingType,
  LobbyRating,
  CongressRating,
  TckRating,
  Position,
  calculateSingleRatingOverall,
  getRatingCategories
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { theStore, theClient } from "../cmak-ui";

@Component
export default class dailyRating extends Vue {
  @Prop({ required: true }) person!: Attendee;
  @Prop({ required: true }) ratingDay!: number;
  @Prop({ required: false }) saveInProgress!: boolean;
  
  myRating: Rating = {
    type: getRatingType(this.person.position!),
    overall: 0
  } as Rating;
  ratingCategories: {label: string, key: string}[] | null = null;

  
  mounted() {
     this.ratingCategories = getRatingCategories(this.person.position!)
      this.myRating = this.person.gameRating?.dailyRatings[this.ratingDay].find(
        (r) => r.judge.id == theStore.person!.id
      )?.rating ?? this.createEmptyRating(this.person.position!)  
  }

  calculateOverall() {
    this.myRating.overall = calculateSingleRatingOverall(this.person, this.myRating)
  }

  createEmptyRating(position: Position): Rating {
    const ratingType=getRatingType(position);
    if(!ratingType){
      throw new Error("unknown rating type for position")
    }
    let newRating: Rating = {type: ratingType, overall: 0}
    getRatingCategories(position).forEach(c => newRating[c.key] = 0)
    return newRating;
  }

  @Emit()
  saveRating(rating: Rating) {
    console.log(rating)
  }
}

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Main from '../views/Main.vue'
import Messages from '../views/Messages.vue'
import Registration from '../views/Registration.vue'
import Profile from '../views/Profile.vue'
import About from '../views/About.vue'
import Preferences from '../views/Preferences.vue'
import MotivationLetter from '../views/MotivationLetter.vue'
import Admin from '../views/Admin.vue'
import RoleAdmin from '../views/RoleAdmin.vue'
import { theStore } from '../cmak-ui'
import Home from '../views/Home.vue'
import ListOfEveryone from '../views/ListOfEveryone.vue'
import Terms from '../views/Terms.vue'
import ServiceOrder from '../views/ServiceOrder.vue'
import AttendeeRating from '../views/AttendeeRating.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      {
        path: 'rating',
        name: 'Hodnocení',
        component: AttendeeRating,
      },
      {
        path: 'registration',
        name: 'Registrace',
        component: Registration,
        children: [
          {
            path: 'preferences',
            name: 'Preference',
            component: Preferences
          },
          {
            path: 'motivation-letter',
            name: 'Motivační dopis',
            component: MotivationLetter
          },
        ]
      },
      // {
      //   path: 'messages',
      //   name: 'Zprávy',
      //   component: Messages
      // },
      {
        path: 'profile',
        name: 'Profil',
        component: Profile,
        props: true
      },
      {
        path: 'the-list',
        name: 'Uživatelé',
        component: ListOfEveryone
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admin
      },
      {
        path: 'roles',
        name: 'Role',
        component: RoleAdmin
      },
      {
        path: 'home',
        name: 'Domů',
        component: Home
      },
      {
        path: 'terms',
        name: 'Všeobecné podmínky',
        component: Terms
      }, 
      {
        path: 'service',
        name: 'Servis',
        component: ServiceOrder
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name != "Welcome" && !theStore.person) {
      console.log("user not authenticated, redirecting to welcome page")
    next({ name: "Welcome" });
  } else {
    next();
  }
});

export default router






































































































































import { Prop, Component, Vue, Emit } from "vue-property-decorator";
import {
  Position,
  PartisanPosition,
  PositionFilter,
  BodyType,
  SenatePosition,
  CongressPosition,
  Party,
  State,
  LobbyType,
  PartyFilter,
  Committee,
  Fraction,
  ApplicableFilter,
  RegistrationInfo,
  Person,
  Attendee,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import {
  shortPositionText,
  filterToText,
  theCmak,
  theStore,
} from "../cmak-ui";

@Component
export default class FilterSelector extends Vue {
  @Prop() hiddenFilters!: PositionFilter[];
  @Prop() shortcuts?: { label: string; generator: () => PositionFilter[] }[];

  committees(): Committee[] {
    return [...theCmak.congressCommittees, ...theCmak.senateCommittees];
  }

  shown(filters: PositionFilter[]): PositionFilter[] {
    return filters.filter((f) => !this.isFilterHidden(f));
  }

  filterToLabel(filter: PositionFilter): string {
    if (!filter) {
      return "N/A";
    }
    return filterToText(filter);
  }

  partyFilters: PositionFilter[] = [Party.Democrat, Party.Republican].map((p) =>
    this.filter("party", p)
  );
  bodyFilters: PositionFilter[] = [
    BodyType.Senate,
    BodyType.Congress,
    BodyType.Government,
    BodyType.Lobby,
    BodyType.TCK,
    //BodyType.Other,
  ].map((b) => this.filter("body", b));
  //TODO: vymyslet lepsi reseni pro Other

  committeeFilters: PositionFilter[] = this.committees().map((c) =>
    this.filter("committee", c.id)
  );
  
  fractionFilters:PositionFilter[] = theCmak.fractions.map(f=>this.filter('fraction',f.id))
  lobbyTypeFilters:PositionFilter[] = [LobbyType.Corp, LobbyType.Np, LobbyType.Un].map(l=>this.filter('lobbyType',l))
 

  filter(filterType: "committee" | "party" | "fraction" | "body" | "state" | "lobbyType",
    value: string):PositionFilter{
    return {type:filterType,value:value}
  }

  addFilters(filters: PositionFilter[]) {
    filters?.forEach(this.addFilter);
  }

  @Emit()
  addFilter(filter: PositionFilter) {}

  isFilterHidden(filter: PositionFilter): boolean {
    return this.hiddenFilters.find(
      (f) => f.type === filter.type && f.value === filter.value
    )
      ? true
      : false;
  }
}

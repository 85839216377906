"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PersonHeader_vue_1 = require("./PersonHeader.vue");
var vue_property_decorator_1 = require("vue-property-decorator");
var cmak_types_1 = require("@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types");
var file_saver_1 = require("file-saver");
var cmak_csv_1 = require("../cmak-csv");
var cmak_ui_1 = require("../cmak-ui");
var AttendeeProfile = /** @class */ (function (_super) {
    __extends(AttendeeProfile, _super);
    function AttendeeProfile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AttendeeProfile.prototype.save = function () {
        var blob = new Blob([cmak_csv_1.exportCsv(this.attendees, [
                ["Jméno", function (a) { return a.name; }],
                ["Pohlaví", function (a) { var _a; return (_a = a === null || a === void 0 ? void 0 : a.personalInfo) === null || _a === void 0 ? void 0 : _a.sex; }],
                ["Id", function (a) { return a.id; }],
                ["Email", function (a) { return a.email; }],
                ["Admin", function (a) { var _a; return ((_a = a.authorizations) === null || _a === void 0 ? void 0 : _a.includes(cmak_types_1.Authorization.ADMIN)); }],
                ["Organizátor", function (a) { var _a; return ((_a = a.authorizations) === null || _a === void 0 ? void 0 : _a.includes(cmak_types_1.Authorization.ORGANIZER)); }],
                ["Pozice", function (a) { return a.position ? cmak_ui_1.shortPositionText(a.position) : ""; }],
                ["Org team", function (a) { var _a; return (_a = a.organizer) === null || _a === void 0 ? void 0 : _a.orgTeam.map(function (t) { return t.name; }).join("/"); }],
                ["Org pozice jméno", function (a) { var _a, _b; return (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.name; }],
                ["Org pozice stát", function (a) { var _a, _b; return (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.state; }],
                ["Org výbor", function (a) { var _a, _b; return (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.committee; }],
                ["Org pozice strana", function (a) { var _a, _b; return (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.party; }],
                ["Org frakce", function (a) { var _a, _b, _c; return (_c = (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.fraction) === null || _c === void 0 ? void 0 : _c.name; }],
                ["Org frakce zkratka", function (a) { var _a, _b, _c; return (_c = (_b = (_a = a === null || a === void 0 ? void 0 : a.organizer) === null || _a === void 0 ? void 0 : _a.orgPosition) === null || _b === void 0 ? void 0 : _b.fraction) === null || _c === void 0 ? void 0 : _c.id; }],
                ["Telefon", function (a) { var _a; return (_a = a.personalInfo) === null || _a === void 0 ? void 0 : _a.phone; }],
                ["Číslo OP", function (a) { var _a, _b; return (_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.idNumber; }],
                ["Adresa", function (a) { var _a, _b, _c, _d; return ((_a = a.personalInfo) === null || _a === void 0 ? void 0 : _a.address) ? ((_b = a.personalInfo) === null || _b === void 0 ? void 0 : _b.address.line1) + ", " + ((_c = a.personalInfo) === null || _c === void 0 ? void 0 : _c.address.city) + ", " + ((_d = a.personalInfo) === null || _d === void 0 ? void 0 : _d.address.zip) : ""; }],
                ["Název školy", function (a) { var _a; return (_a = a === null || a === void 0 ? void 0 : a.school) === null || _a === void 0 ? void 0 : _a.name; }],
                ["Adresa školy", function (a) { var _a, _b, _c, _d; return ((_a = a.school) === null || _a === void 0 ? void 0 : _a.address) ? ((_b = a.school) === null || _b === void 0 ? void 0 : _b.address.line1) + ", " + ((_c = a.school) === null || _c === void 0 ? void 0 : _c.address.city) + ", " + ((_d = a.school) === null || _d === void 0 ? void 0 : _d.address.zip) : ""; }],
                ["Ubytování - noci", function (a) { var _a, _b; return (_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.nights; }],
                ["Ubytování - preferovaná kolej", function (a) { var _a, _b; return (_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.dormPreference; }],
                ["Ubytování - spolubydlící - pokoj", function (a) { var _a, _b, _c; return (_c = (_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.roommatePreference) === null || _c === void 0 ? void 0 : _c.join(","); }],
                ["Ubytování - spolubydlící - buňka", function (a) { var _a, _b, _c; return (_c = (_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.cellmatePreference) === null || _c === void 0 ? void 0 : _c.join(","); }],
                ["Datum narození", function (a) { var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l; return ((_b = (_a = a.services) === null || _a === void 0 ? void 0 : _a.accommodationInfo) === null || _b === void 0 ? void 0 : _b.birthday) ? ((_e = (_d = (_c = a.services) === null || _c === void 0 ? void 0 : _c.accommodationInfo) === null || _d === void 0 ? void 0 : _d.birthday) === null || _e === void 0 ? void 0 : _e.day) + "." + ((_h = (_g = (_f = a.services) === null || _f === void 0 ? void 0 : _f.accommodationInfo) === null || _g === void 0 ? void 0 : _g.birthday) === null || _h === void 0 ? void 0 : _h.month) + "." + ((_l = (_k = (_j = a.services) === null || _j === void 0 ? void 0 : _j.accommodationInfo) === null || _k === void 0 ? void 0 : _k.birthday) === null || _l === void 0 ? void 0 : _l.year) : undefined; }],
                ["Obědy", function (a) { var _a; return (_a = a.services) === null || _a === void 0 ? void 0 : _a.lunch; }],
                ["Obědové preference", function (a) { var _a; return (_a = a.services) === null || _a === void 0 ? void 0 : _a.specialFoodNeeds; }],
                ["MHD", function (a) { var _a; return (_a = a.services) === null || _a === void 0 ? void 0 : _a.transport; }],
                ["Zaplaceno", function (a) { var _a, _b, _c, _d; return (_b = (_a = a.financeInfo) === null || _a === void 0 ? void 0 : _a.paid) !== null && _b !== void 0 ? _b : 0 + ((_d = (_c = a.financeInfo) === null || _c === void 0 ? void 0 : _c.balanceCorrection) !== null && _d !== void 0 ? _d : 0); }],
                ["Celkem poplatky", function (a) { var _a; return (_a = a.financeInfo) === null || _a === void 0 ? void 0 : _a.fees.reduce(function (sum, fee) { return sum + fee.price; }, 0); }],
                ["Variabilní symbol", function (a) { var _a; return (_a = a.financeInfo) === null || _a === void 0 ? void 0 : _a.varSymbol; }],
                ["Náhradník", function (a) { return a.backupRoleCandidate; }],
                ["Strana", function (a) { var _a; return (a === null || a === void 0 ? void 0 : a.position) ? (_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.party : undefined; }],
                ["Frakce", function (a) { var _a, _b; return (a === null || a === void 0 ? void 0 : a.position) ? (_b = (_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.fraction) === null || _b === void 0 ? void 0 : _b.name : undefined; }],
                ["Stát", function (a) { var _a; return (a === null || a === void 0 ? void 0 : a.position) ? (_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.state : undefined; }],
                ["Výbor", function (a) { var _a; return (a === null || a === void 0 ? void 0 : a.position) ? (_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.committee : undefined; }],
                ["Ministr", function (a) { var _a, _b; return (a === null || a === void 0 ? void 0 : a.position) ? (_b = (_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.department) === null || _b === void 0 ? void 0 : _b.name : undefined; }],
                ["Lobby společnost", function (a) { var _a, _b; return (a === null || a === void 0 ? void 0 : a.position) ? (_b = (_a = a.position) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.name : undefined; }],
                ["Novinář", function (a) { var _a; return ((_a = a === null || a === void 0 ? void 0 : a.position) === null || _a === void 0 ? void 0 : _a.body) == cmak_types_1.BodyType.TCK ? "TCK" : undefined; }]
            ])]);
        file_saver_1.saveAs(blob, 'export.csv');
    };
    __decorate([
        vue_property_decorator_1.Prop({ required: true })
    ], AttendeeProfile.prototype, "attendees", void 0);
    AttendeeProfile = __decorate([
        vue_property_decorator_1.Component({ components: { PersonHeader: PersonHeader_vue_1.default } })
    ], AttendeeProfile);
    return AttendeeProfile;
}(vue_1.default));
exports.default = AttendeeProfile;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import { theStore, theConfig } from "./cmak-ui";
import awsExports from './aws-exports'
import {Auth} from '@aws-amplify/auth'
import API from '@aws-amplify/api'


Auth.configure(awsExports);
API.configure(awsExports);

theConfig.initialize(awsExports.aws_project_region, awsExports.aws_user_files_s3_bucket)

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

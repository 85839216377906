



import Vue from 'vue'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import Delta from 'quill-delta'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class QuillContent extends Vue {
  @Prop() content!: Delta

  converted (): string {    
    return new QuillDeltaToHtmlConverter(this.content.ops, {}).convert()
  }
}

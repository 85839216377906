
























import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import { Address } from '@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types'

@Component
export default class AddressEditor extends Vue {
  @Prop({required: false}) address?:Address
    line1 = "" 
    city = ""
    zip: number | null = null
    rules = {
      line1: [val => (val || '').length > 0 || 'Povinné pole'],
      city: [val => (val || '').length > 1 || 'Povinné pole'],
      zip: [val => (val || '').toString().length == 5 || 'Povinné pole']
    };
    valid:boolean | null = null;
  
  mounted(){
    if(this.address){
      this.line1=this.address.line1
      this.city=this.address.city
      this.zip=this.address.zip
    }
    
  }

  
  submit() {
    //console.log({line1: this.line1, city: this.city, zip: this.zip!})
    this.addressChanged({line1: this.line1, city: this.city, zip: this.zip!})
  }
  
  onChange() {      
      this.valid = (this.$refs.form as any).validate();
      if (this.valid) {
        this.submit()
      }
      this.validity(this.valid!)
  }

  @Emit()
  addressChanged(address:Address) {}

  @Emit()
  validity(valid:boolean) {}

}







































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import {
  PriceList,
  Services,
  FinanceInfo,
  Dorm,
  Address,
  Sex,
  feeGenerator,
  Fee,
  Authorization,
  getSimpleDateFromDate,
  getDateFromSimpleDate
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import { theStore, theClient, theCmak, computeBalance } from "../cmak-ui";
import AddressEditor from "../components/AddressEditor.vue";
import { CmakDefinition } from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-2022";
import { EmittingCmakClient } from "../cmak-client";
import FeeList from "../components/FeeList.vue";
import QrCodeCust from "../components/QrCodeCust.vue";
import equal from "fast-deep-equal";
import ConsentDialog from "../components/ConsentDialog.vue";

@Component({
  components: { AddressEditor, FeeList, QrCodeCust, ConsentDialog },
})
export default class ServiceOrder extends Vue {
  person = theStore.person;
  authorizations = theStore.person?.authorizations;
  theClient = new EmittingCmakClient(theClient, this);
  menu = false;
  progress = 1;
  date = new Date().toISOString().substr(0, 10);
  standardNights: boolean = false;
  extraNight: boolean = false;
  idNumber: number | null = null;
  phone: number | null = null;
  nights: number | null = null;
  dormPreference: Dorm | null = null;
  // roommatePreference: string[] | null = null;
  // cellmatePreference: string[] | null = null;
  lunch: boolean | null = null;
  transport: boolean | null = null;
  birthday: Date | null = null;
  formattedBirthday: String = "";
  heyrovskeho = Dorm.Heyrovskeho;
  bolevecka = Dorm.Bolevecka;
  roommateString: string | null = null;
  cellmateString: string | null = null;
  specialFoodNeeds: string | null = null;
  address: Address | null = null;
  sex: Sex | null = null;
  man = Sex.MALE;
  woman = Sex.FEMALE;
  //services: Services | null = null;
  prices: PriceList = theCmak.priceList;
  orgPrices: PriceList = theCmak.orgPriceList;
  fees: Fee[] | null = null;

  dataSaving: boolean = false;
  payment = false;
  truth = true;
  consent = false;

  rules = {
    phoneNum: [
      (val) =>
        ((val || "").toString().length >= 9 && !isNaN(Number(val))) ||
        "Povinné pole, formát XXXXXXXXX",
    ],
    idNum: [
      (val) =>
        ((val || "").toString().length >= 9 && !isNaN(Number(val))) ||
        "Povinné pole, formát XXXXXXXXX",
    ],
    twoRoommates: [
      (val) =>
        val == null ||
        val == "" ||
        String(val).match(
          /^(?<mate1>[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+\s[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)\s*(((,\s*(?<mate2>[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+\s+[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)\s*))){0,1}$/
        ) ||
        "Jména a příjmení jednoho nebo dvou lidí oddělená čárkou",
    ],
    oneRoommate: [
      (val) =>
        val == null ||
        val == "" ||
        String(val).match(
          /^(?<mate1>[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+\s+[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)\s*$/
        ) ||
        "Jméno a příjmení",
    ],
  };

  created() {
    if (theStore.person?.services) {
      const services = theStore.person.services;
      this.idNumber = services.accommodationInfo?.idNumber ?? null;
      this.phone = theStore.person.personalInfo?.phone ?? null;
      this.nights = services.accommodationInfo?.nights ?? null;
      this.standardNights = this.nights ? true : false;
      this.extraNight = this.nights == 6;
      this.dormPreference = services.accommodationInfo?.dormPreference ?? null;
      this.roommateString =
        services.accommodationInfo?.roommatePreference?.join(", ") || null;
      this.cellmateString =
        services.accommodationInfo?.cellmatePreference?.join(", ") || null;
      this.lunch = services.lunch ?? null;
      this.transport = services.transport ?? null;
      this.birthday = services.accommodationInfo?.birthday ? getDateFromSimpleDate(services.accommodationInfo?.birthday) : null;
      this.formattedBirthday = this.formatDate(this.birthday);
      this.specialFoodNeeds = services.specialFoodNeeds ?? null;
      this.address = theStore.person.personalInfo?.address ?? null;
      this.sex = theStore.person.personalInfo?.sex ?? null;
    }
    this.consent = this.person?.consent ? true : false;
  }
  isOrganizer() {
     return this.person?.authorizations?.includes(Authorization.ORGANIZER);
  }

  roomMateRules() {
    if (this.dormPreference == this.heyrovskeho) {
      return this.rules.twoRoommates;
    } else {
      return this.rules.oneRoommate;
    }
  }

  computeBalance(financeInfo: FinanceInfo): number {
    return computeBalance(financeInfo)
  }

  isOrderEqual(): boolean {
    console.log(
      equal(this.generateServices(), theStore.person?.services) &&
        equal(
          {
            phone: this.phone,
            sex: this.sex,
            address: this.address,
          },
          theStore.person?.personalInfo
        )
    );
    console.log(
      JSON.stringify(this.generateServices()) +
        " + " +
        JSON.stringify(theStore.person?.services)
    );
    console.log(
      JSON.stringify({
        phone: this.phone,
        sex: this.sex,
        address: this.address,
      }) +
        " + " +
        JSON.stringify(theStore.person?.personalInfo)
    );

    return (
      equal(this.generateServices(), theStore.person?.services) &&
      equal(
        {
          phone: this.phone,
          sex: this.sex,
          address: this.address,
        },
        theStore.person?.personalInfo
      )
    );
  }

  @Watch("standardNights")
  @Watch("extraNight")
  accomodationChanged() {
    if (this.standardNights) {
      this.nights = 5;
      if (this.extraNight) {
        this.nights = 6;
      }
    } else {
      this.nights = 0;
      this.extraNight = false;
    }
  }

  checkChange() {
    if (!this.isOrderEqual()) {
      this.payment = false;
    } else {
      this.payment = true;
    }
  }

  saveAdress(address: Address) {
    this.address = address;
  }

  generateServices(): Services {
    let services = {
      accommodationInfo: this.nights
        ? {
            idNumber: this.idNumber!,
            nights: this.nights!,
            birthday: getSimpleDateFromDate(this.birthday!)!,
            dormPreference: this.dormPreference ?? undefined,
            roommatePreference:
              this.roommateString?.split(",").map((n) => n.trim()) ?? undefined,
            cellmatePreference:
              this.cellmateString?.split(",").map((n) => n.trim()) ?? undefined,
          }
        : undefined,
      lunch: this.lunch ?? undefined,
      specialFoodNeeds: this.specialFoodNeeds ?? undefined,
      transport: this.transport ?? undefined,
    };
    return services!;
  }

  savePersonalInfo() {
    if (this.address && this.sex && this.phone) {
      let personalInfo = {
        address: this.address,
        phone: this.phone,
        sex: this.sex,
      };
      this.theClient
        .updatePersonalInfo(personalInfo)
        .then((p) => (theStore.person = p));
    }
  }

  saveAddress(address: Address) {
    this.address = address;
  }

  updateServices() {
    this.dataSaving = true;
    this.theClient.updateServices(this.generateServices()).then((p) => {
      this.dataSaving = false;
      theStore.person = p;
    });
  }

  formatDate(date:Date|null):String{
    let simple = getSimpleDateFromDate(date);
    return simple?`${simple.day}.${simple.month}.${simple.year}`:""
  }

  @Watch("birthday")
  onBirthdayChange(birthday:Date|null){
    this.formattedBirthday=this.formatDate(birthday)

  }

  @Watch("services")
  feeGenerator(services: Services) {
    if (this.person?.authorizations?.includes(Authorization.ORGANIZER)) {
      console.log(feeGenerator(services, this.orgPrices));
      this.fees = feeGenerator(services, this.orgPrices);
    }else {
      console.log(feeGenerator(services, this.prices));
      this.fees = feeGenerator(services, this.prices);
    }
  }

  canProceed(step: number): boolean {
    switch (step) {
      case 1: {
        if (this.nights) {
          return this.idNumber ? true : false;
        } else {
          return true;
        }
      }
      case 2: {
        if (this.phone && this.address && this.sex) {
          if (this.nights) {
            return this.birthday ? true : false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
      default: {
        return false;
      }
    }
  }
}

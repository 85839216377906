"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cached = void 0;
var Cached = /** @class */ (function () {
    function Cached(supplier, ttlInSec, label) {
        if (ttlInSec === void 0) { ttlInSec = 60; }
        if (label === void 0) { label = ""; }
        this.supplier = supplier;
        this.ttlInSec = ttlInSec;
        this.label = label;
    }
    Cached.prototype.get = function () {
        var _this = this;
        var _a, _b;
        if (this.activePromise) {
            return this.activePromise;
        }
        else if (this.value &&
            (((_b = (_a = this.stored) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) > (Date.now() - this.ttlInSec * 1000))) {
            console.log("cache hit " + this.label);
            return new Promise(function (resolve) { return resolve(_this.value); });
        }
        else {
            console.log("cache miss " + this.label);
            return (this.activePromise = this.supplier().then(function (a) { return _this.store(a); }));
        }
    };
    Cached.prototype.store = function (a) {
        this.stored = new Date();
        this.value = a;
        this.activePromise = undefined;
        return a;
    };
    Cached.prototype.purge = function () {
        console.log("cache purge " + this.label);
        this.value = undefined;
        this.stored = undefined;
    };
    Cached.prototype.preCache = function (a) {
        var _this = this;
        if (typeof a["then"] === "function") {
            this.activePromise = a.then(function (a) { return _this.store(a); });
        }
        else {
            this.store(a);
        }
    };
    return Cached;
}());
exports.Cached = Cached;

import { render, staticRenderFns } from "./Preferences.vue?vue&type=template&id=26d93725&scoped=true&"
import script from "./Preferences.vue?vue&type=script&lang=ts&"
export * from "./Preferences.vue?vue&type=script&lang=ts&"
import style0 from "./Preferences.vue?vue&type=style&index=0&id=26d93725&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26d93725",
  null
  
)

export default component.exports












































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { EmittingCmakClient } from "../cmak-client";
import {
  theStore,
  theClient,
  theCmak,
  shortPositionText,
  calculateOverall,
  averageRatingInCategories,
  RatedAttendee,
  filterToText,
} from "../cmak-ui";
import PersonHeader from "../components/PersonHeader.vue";
import {
  Attendee,
  RatingResponsibilities,
  isWithinFilters,
  ApplicableFilter,
  PositionFilter,
  Rating,
  GameRating,
  Position,
  getRatingType,
  getRatingCategories,
  Authorization,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-types";
import {
  determineRatingResponsibility,
  getCurrentRatingDay,
} from "@/../amplify/backend/function/cmakappLambdaLayer/opt/cmak-2022";
import RatingOverview from "../components/RatingOverview.vue";
import dailyRating from "../components/dailyRating.vue";
import adjustmentRating from "../components/adjustmentRating.vue";
import FilterSelector from "../components/FilterSelector.vue";
import RatingExport from "../components/RatingExport.vue";

function getMapped(ra: RatedAttendee): RatedAttendee {
  if (ra.attendee.gameRating) {
    const averageRating = averageRatingInCategories(ra.attendee.gameRating);
    Object.entries(averageRating).forEach((entryAvg) => {
      const wantedCategory = getRatingCategories(ra.position!).find(
        (category) => category.key === entryAvg[0]
      );
      if (wantedCategory) {
        ra[wantedCategory.key] = entryAvg[1];
      }
    });
  }
  return ra;
}

@Component({
  components: {
    PersonHeader,
    RatingOverview,
    dailyRating,
    adjustmentRating,
    FilterSelector,
    RatingExport,
  },
})
export default class AttendeeRating extends Vue {
  dataLoading: boolean = false;
  theClient = new EmittingCmakClient(theClient, this);
  attendeesToRate: Attendee[] | null = null;
  ratingDialog: boolean = false;
  userResponsibilities: RatingResponsibilities | null = null;
  detailAttendee: Attendee | null = null;
  detailGameRating: GameRating | null = null;
  detailRa: RatedAttendee | null = null;
  ratedAttendees: RatedAttendee[] | null = null;
  tabs: number = 1;
  overviewDialog: boolean = false;
  filters: PositionFilter[] = [];
  nameFilter: string = "";
  filtersExpanded: boolean = false;
  savingRating: boolean = false;

  created() {
    this.dataLoading = true;
    this.userResponsibilities = determineRatingResponsibility(theStore.person!);
    this.theClient
      .getAttendeesToRate()
      .then((attendees) => {
        this.attendeesToRate = attendees.sort((a, b) => {
          if (this.hasMoreThanVisibility(a) && this.hasMoreThanVisibility(b)) {
            return 0;
          } else if (
            this.hasMoreThanVisibility(b) &&
            !this.hasMoreThanVisibility(a)
          ) {
            return 1;
          } else {
            return -1;
          }
        });
        console.log("Attendees to rate: " + this.attendeesToRate);
      })
      .finally(() => (this.dataLoading = false));

    if (this.isAdmin()) {
      this.setRatedAttendees();
    }
  }

  isAdmin(): boolean {
    return theStore.person!.authorizations?.includes(Authorization.ADMIN)
      ? true
      : false;
  }

  shortPositionText = shortPositionText;

  setRatedAttendees() {
    this.theClient.getAllAttendees().then((attendees) => {
      this.ratedAttendees = attendees
        .filter((a) => a.gameRating)
        .map((a) => new RatedAttendee(a))
        .sort((a, b) => b.overallRating! - a.overallRating!)
        .map((ra, index) => {
          ra.rank = index + 1;
          return getMapped(ra);
        });
    });
  }

  canRate(attendee: Attendee): boolean {
    if (this.userResponsibilities) {
      return isWithinFilters(
        attendee,
        ApplicableFilter.makeApplicable(this.userResponsibilities?.dailyRating)
      );
    } else {
      return false;
    }
  }

  canAdjust(attendee: Attendee): boolean {
    if (this.userResponsibilities) {
      return isWithinFilters(
        attendee,
        ApplicableFilter.makeApplicable(this.userResponsibilities?.adjustment)
      );
    } else {
      return false;
    }
  }

  hasMoreThanVisibility(attendee: Attendee): boolean {
    if (this.userResponsibilities) {
      return (
        isWithinFilters(
          attendee,
          ApplicableFilter.makeApplicable(
            this.userResponsibilities?.dailyRating
          )
        ) ||
        isWithinFilters(
          attendee,
          ApplicableFilter.makeApplicable(this.userResponsibilities?.adjustment)
        )
      );
    } else {
      return false;
    }
  }

  hasMyRatingForDay(attendee: Attendee): Array<boolean> {
    let daysRatedByMe: Array<boolean> = [];
    daysRatedByMe[0] = attendee.gameRating?.dailyRatings[0].find(
      (r) => r.judge.id == theStore.person!.id
    )
      ? true
      : false;
    daysRatedByMe[1] = attendee.gameRating?.dailyRatings[1].find(
      (r) => r.judge.id == theStore.person!.id
    )
      ? true
      : false;
    daysRatedByMe[2] = attendee.gameRating?.dailyRatings[2].find(
      (r) => r.judge.id == theStore.person!.id
    )
      ? true
      : false;

    return daysRatedByMe;
  }

  openRatingDialog(attendee: Attendee) {
    this.detailAttendee = attendee;
    this.ratingDialog = true;
  }

  openOverviewDialog(ra: RatedAttendee) {
    this.detailRa = ra;
    this.overviewDialog = true;
  }

  posToText(position: Position): string {
    return position ? shortPositionText(position) : "";
  }
  filterToText(filter: PositionFilter): string {
    return filterToText(filter);
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
  }

  addFilter(filter: PositionFilter) {
    console.log("new filter", filter.type, filter.value);
    if (this.isNewFilter(filter.type, filter.value)) {
      this.filters.push({ type: filter.type, value: filter.value });
    }
  }

  private isNewFilter(filterType: string, value: string): boolean {
    return (
      this.filters.filter((f) => f.type === filterType && f.value === value)
        .length === 0
    );
  }

  savePersonRating(rating: Rating, ratingDay: number) {
    this.savingRating = true;
    console.log("AttendeeRating: " + rating.overall);
    theClient
      .setDailyRating(this.detailAttendee!.id, ratingDay, rating)
      .then((attendee) => {
        this.detailAttendee = attendee;
        this.attendeesToRate!.splice(
          this.attendeesToRate!.findIndex(
            (a) => a.id == this.detailAttendee!.id
          ),
          1,
          attendee
        );
      })
      .finally(() => (this.savingRating = false));
  }

  setAdjustment(adjustment: number | undefined) {
    this.savingRating = true;
    if (adjustment || adjustment == 0) {
      theClient
        .setRatingAdjustment(this.detailAttendee!.id, adjustment)
        .then((attendee) => {
          this.detailAttendee = attendee;
        }).finally(() => this.savingRating = false);
    }
  }

  filtered(ratedAttendees: RatedAttendee[]) {
    if (!ratedAttendees) {
      return [];
    }
    const applicableFilters: ApplicableFilter[] =
      ApplicableFilter.makeApplicable(this.filters);
    return ratedAttendees.filter((ra) => {
      if (this.filters) {
        if (
          !(
            ra.position &&
            applicableFilters.every((f) => f.matches(ra.position!))
          )
        ) {
          return false;
        }
      }
      if (this.nameFilter?.length > 0) {
        if (!(ra.attendee.name == this.nameFilter)) {
          return false;
        }
      }
      return true;
    });
  }
}
